


//const IDChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const IDNames = [
  'Wizard',
  'Paladin',
  'Artificer',
  'Rogue',
  'Barbarian',
  'Fighter',
  'Cleric',
  'Bard',
  'Sorcerer',
  'Warlock',
  'Ranger',
  'Druid',
  'Monk',

  'Sword',
  'Shield',
  'Staff',
  'Wand',
  'Spellbook',

  'Goblin',
  'Dungeon',
  'Kobold',
  'Dragon',
  'Troll',
  'Lich',
  'Elemental',
  'Mage',
  'Beholder',
  'Bugbear',
  'Centaur',
  'Gnoll',
  'Gru',

  'Gnome',
  'Elf',
  'Dwarf',
  'Orc',
  'Human',
  'Tiefling',
  'Warforged',
  'Halfling',
  'Tortle',
  'Leonin',
  'Kenku',
  'Harengon',

  'Gold',
  'Silver',
  'Copper',
  'Platinum',
  
  'Fireball',
  'Wish',
  'Mending',
  'Blink',
  'Cantrip',
  'Crit',
  'Revivify',
  'Stoneskin',
  'Levitate',
  'Knock',
  'Identify',
  'Polymorph',

  'Dodge',
  'Dash',
  'Grapple',
]; //42


function MakeRandID(len) {
  var rtn = '';
  for (var i = 0; i < len; i++) {
    rtn += IDNames[Math.floor(Math.random()*IDNames.length)];
  }
  return rtn;
}

export default MakeRandID;