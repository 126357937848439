
import React, {Component} from 'react';
import PropTypes from 'prop-types'

class RemoteEntry extends Component {

  render() {
    return <></>;
  }
}

RemoteEntry.propTypes = {
  onInput: PropTypes.func,
  data: PropTypes.object,
}
const noop = ()=>{};
RemoteEntry.defaultProps = {
  onInput: noop,
  data: {},
}

export default RemoteEntry;