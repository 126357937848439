import React, {Component} from 'react';
import ReactPlayer from 'react-player'
import ReactHowler from 'react-howler'

class Sound extends Component{
    constructor(props) {
        super(props);
        this.state = {
            src: props.src,
            fade: props.fade,
            playcount: props.playcount, // if number increases, play again. If it decreases, stop all instances
            dodging: props.dodging,
            type: props.type,

            // Transient
            fileObj: undefined,
            playing:false,

            filemode: undefined,
        }

        this._innerplaycount = 0;

        this.playerRef = React.createRef();

        this.onSoundFinished = this.onSoundFinished.bind(this);
    }

    setupFile()
    {
        // If file, create howl
        // If youtube, create video thingy
        //console.log("SETUP FILE", this.state.type, this.state.src)
        if (this.state.type === "link") {
            
            if (this.state.src.indexOf("youtu") == -1) {
                var fsurl = this.state.src.replace("displays","previews").replace(/_wave.*/,"-lq.mp3")
            
                this.setState({src: fsurl, filemode:'howl'});
                /*this.setState({fileObj: (
                    <ReactHowler
                        ref={this.playerRef}
                        src={fsurl}
                        playing={this.state.playing}
                        onEnd={this.onSoundFinished}
                        volume={this.state.fade}
                   />)});*/
                this.setFade(this.state.fade);
            }
            else {
                this.setState({filemode:'player'});
                /*this.setState({fileObj: (
                    <ReactPlayer 
                        ref={this.playerRef}
                        className="hidden"
                        url={this.state.src}
                        playing={this.state.playing}
                        />)});*/
            }
        }
        else {
            this.setState({filemode:'howl'});
            /*this.setState({fileObj: (
                <ReactHowler
                    ref={this.playerRef}
                    src='http://goldfirestudios.com/proj/howlerjs/sound.ogg'
                    playing={this.state.playing}
                    onEnd={this.onSoundFinished}
                    volume={this.state.fade}
                />)});*/
            this.setFade(this.state.fade);
        }

        // Save it to fileObj
    }
    componentDidMount() {
        this.setupFile();
    }

    play() {
        console.log('play', this.state.fade,this.state.filemode, this.state.playing, this._innerplaycount, this.state.playcount)
        // Start playing
        if (this.state.filemode == 'howl') {
            /*this.playtimer = setTimeout(() => {

                this.onSoundFinished()
                clearTimeout(this.playtimer);
            },3000)*/

            if (this.playerRef.current) {
                if (this.playerRef.current.howler) {
                    var sprites = Object.keys(this.playerRef.current.howler._sprite);
                    if (sprites.length > 1 && sprites.indexOf('__default') != -1) {
                        sprites.splice(sprites.indexOf('__default'), 1);
                    }

                    var spriteind = 0;
                    if (this.props.prng) spriteind = Math.floor(this.props.prng() * sprites.length);
                    var sprite = sprites[spriteind];
                    console.log("PLAYING SPRITE", sprite, this.playerRef.current.howler._sprite[sprite])
                    this.playerRef.current.howler._sprite['__default'] = this.playerRef.current.howler._sprite[sprite];
                    
                    var id = this.playerRef.current.howler.play(sprite);
                    var finalPitch = 1.0;

                    if (this.props.prng) {
                        var pitchDelta = parseFloat(this.props.pitchMax) - parseFloat(this.props.pitchMin);
                        var pitchRand = this.props.prng() * pitchDelta + parseFloat(this.props.pitchMin);
                        finalPitch= pitchRand;
                    }
                    this.playerRef.current.howler.rate(finalPitch,id);
                }
                else console.log("No howler!")
            }
            else console.log('no playerref!')
            this._innerplaycount++;
            this.setState({playing:true});
        }
        else if (this.state.filemode=='player') {
            if (this.state.playing) {
                this.playerRef.current.seekTo(0);
                //this.setState({playing:false});
                //setTimeout(this.play.bind(this), 100);
                return;
            }
            this.setState({playing:true});
        }
    }
    stop() {
        // Stop playing
        this._innerplaycount = 0;
        this.setState({playing:false});
        if (this.state.filemode == 'howl') {
            clearTimeout(this.playtimer);
            
            if (this.playerRef.current) {
                if (this.playerRef.current.howler) {
                    this.playerRef.current.howler.stop();
                }
            }
        }
        else if (this.state.filemode=='player') {
        }
    }

    startDodge() {
        console.log("START DODGE");
    }

    stopDodge() {
        console.log("STOP DODGE");
    }

    setFade(val) {
        //console.log("FADE CHANGE", val);
        this.setState({fade:val});

        if (this.playerRef.current) {
            if (this.playerRef.current.howler) {
                this.playerRef.current.howler.volume(val);
            }
        }
    }

    onHowlerLoad() {
        this.loadSprites();
    }

    loadSprites() {
        
        // Push sprite list into howler
        if (this.playerRef.current && this.playerRef.current.howler) {
            var sprites = {};
            var regions = this.props.regions || {};
            Object.keys(regions).map(key => {
                var region = regions[key];
                sprites[key] = [region.start*1000, (region.end-region.start)*1000];
            })
            if (Object.keys(regions).length == 0) {
                sprites = {__default: [0, this.playerRef.current.howler._duration * 1000]};
            }
            else {
                // We give a __default in case things get played too early. We clean it up on first play
                sprites["__default"] = [0,0];
            }
            var oldsprites = JSON.stringify(this.playerRef.current.howler._sprite);
            var newsprites = JSON.stringify(sprites);
            if (oldsprites != newsprites) {
                this.playerRef.current.howler._sprite = sprites;
            }
        }
    }

    onSoundFinished() {
        // callback to parent that this sound is done
        // so that it may decide to loop
        // It should respond by setting playing to false
        this._innerplaycount--;
        if (this._innerplaycount <= 0) {
            this._innerplaycount = 0;
            this.setState({playing:false})
        };
        if (this.props.onSoundFinished) {
            this.props.onSoundFinished(this, this.state.playing);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.playcount !== this.props.playcount) {
            // Handle playing change
            if (this.props.playcount > prevProps.playcount) {
                this.play();
            }
            else if (this.props.playcount < prevProps.playcount) {
                this.stop();
            }
        }

        this.loadSprites();
        
        if (prevProps.dodging !== this.props.dodging) {
            // Handle playing change
            if (this.props.dodging) {
                this.startDodge();
            }
            else {
                this.stopdodging();
            }
        }

        if (prevProps.fade !== this.props.fade) {
            this.setFade(this.props.fade);
        }
    }
    render() {
        return (
            <div key={this.props.fkey} className="soundContainer">
                {this.state.filemode=='howl' ? (
                    <ReactHowler
                        ref={this.playerRef}
                        src={this.state.src}
                        onLoad={this.onHowlerLoad.bind(this)}
                        playing={this.state.playing}
                        onEnd={this.onSoundFinished}
                        volume={this.state.fade}
                    />
                    ):<></>}
                {this.state.filemode=='player' ? (
                    <ReactPlayer 
                        className="hidden"
                        ref={this.playerRef}
                        url={this.state.src}
                        volume={this.state.fade}
                        playing={this.state.playing}
                        onEnded={this.onSoundFinished}
                        />
                    ):<></>}
            </div>
        );
    }
}

export default Sound;