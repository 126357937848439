
import { 
  Box,
  TextField,
  Paper,
  Container,
  Button,
  Typography,
  Modal,
  AppBar,
  Toolbar,
  Stack,
  IconButton,
 } from '@mui/material';
 import MenuIcon from '@mui/icons-material/Menu';
 import { NavLink } from "react-router-dom";
 import React from 'react';

var TopBar = (props) => {


    return <>
      <AppBar position="static">
        <Toolbar variant="dense">
            <Stack direction="row" spacing={2}>
                <Button variant={props.page=="host" ? 'outlined':'text'} color="inherit" component={NavLink} to="/">
                    Host
                </Button>
                <Button variant={props.page=="remote" ? 'outlined':'text'} color="inherit" component={NavLink} to="/remote">
                    Remote
                </Button>
                <Button variant={props.page=="player" ? 'outlined':'text'} color="inherit" component={NavLink} to="/player">
                    Player
                </Button>
                {/*<Button variant={props.page=="about" ? 'outlined':'text'} color="inherit" component={NavLink} to="/about">
                    About
                </Button>
                <Button variant={props.page=="help" ? 'outlined':'text'} color="inherit" component={NavLink} to="/help">
                    Help
                </Button>*/}
            </Stack>
            
            <Box sx={{ml:'auto'}}>
                {props.children}
            </Box>
        </Toolbar>
      </AppBar>
    </>
};

export default TopBar;