import React from 'react';
import TopBar from "../components/TopBar"

const NoPage = () => {
    return <>
      <TopBar page="404">
      </TopBar>
      <h1>404</h1>
    </>;
  };
  
  export default NoPage;
  