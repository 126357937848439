import React, {Component} from 'react';
import {v4 as uuid } from 'uuid';

import Sound from './Sound'
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip,
    Slider,
    Button,
    IconButton,
    Grid,
    Paper,
    Box,
 } from '@mui/material/';
import DataManager from '../data/DataManager';
import PeerManager from '../PeerManager';
import DraggingStatus from '../GlobalDragStatus';

import VolumeUp from '@mui/icons-material/VolumeUp';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import LoopIcon from '@mui/icons-material/Loop';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import "./Track.scss"
import { Stack } from '@mui/system';
import { Droppable } from 'react-beautiful-dnd';

import { WaveSurfer, WaveForm, Region } from 'wavesurfer-react';
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min";

const transitionTimestep = 50;

class Track extends Component{
    constructor(props) {
        super(props);

        this._track = DataManager.library.getTrack(props.track);
        // Make an array of playcounts of 0's for each file
        var fileList = this._track.files || [];
        const playcounts = fileList.map((file)=>{return {key:file.key, value:0}; });

        this._waveRefs = {};
        fileList.forEach(file => {
            this._waveRefs[file.key] = React.createRef();
        })

        this.state = {
            // Persistent properties
            track_id: this._track.uuid,
            name: this._track.name || "New Track",
            fade: this._track.fade || 200,
            fadeScalar: this._track.fadeScalar || 1.0,
            loop: this._track.loop || false,
            files:fileList,
            pitchRange: this._track.pitchRange || [1.0,1.0],

            transitionDuration: this._track.transitionDuration || 1500,
            transitionFade: 0.0,

            // Transient
            fadeScalarText: (this._track.fadeScalar||1.0) + "",
            playcount: playcounts,
            playing: false,
            dodging: false,
            showFiles: false,
            showNewFile: false,
            showConfig: false,
            transitioning: false,

            newFileIsLink: true,
            newFileLink: "",
            newFileData: "https://",
            newFileName: "",

            draggingStatusActive: DraggingStatus.dragging,
            draggingStatusType: DraggingStatus.type,

        }

        this._persistent = [
            "track_id",
            "name",
            "playing",
            "fade",
            "fadeScalar",
            "loop",
            "files",
            "pitchRange",
        ]

        this.transitionInterval = null;
        this.transitionCounter = 0.0;

        this.onSoundFinished = this.onSoundFinished.bind(this);

        this.playClick = this.playClick.bind(this);
        this.stopClick = this.stopClick.bind(this);
        this.filesClick = this.filesClick.bind(this);

        this.addFileClick = this.addFileClick.bind(this);
        this.submitFileClick = this.submitFileClick.bind(this);
        this.toggleNewFileTypeClick = this.toggleNewFileTypeClick.bind(this);
        this.newFileFileChanged = this.newFileFileChanged.bind(this);
        this.newFileLinkChanged = this.newFileLinkChanged.bind(this);
        this.removeFileClicked = this.removeFileClicked.bind(this);
    }
    
    getVolume(fade) {
        var perc = fade / 1024;
        var val = Math.log10(1 + perc*9);
        return val;
    }

    onRemoteMessage(e) {
        var data = e.detail;
        if (data.track === this.state.track_id) {
            console.log("Got remote message for trackid", this.state.track_id, data);

            if (data.data.action === 'play') {
                this.playClick();
            }
            else if (data.data.action === 'stop') {
                this.stopClick();
            }
            else if (data.data.action === 'fade') {
                this.setState({fade:data.data.value});
            }
            else if (data.data.action === 'update') {
                this._track.load(data.data.value);
                this._onTrackChanged({});
            }
            // Handle remote controls such as play, stop, or fade
        }
    }
    componentDidMount() {
        if (PeerManager) {
            this.remoteMessageBinding = this.onRemoteMessage.bind(this);
            PeerManager.addEventListener('remote_data', this.remoteMessageBinding);
        }

        this._trackonchangedbinding = this._onTrackChanged.bind(this);
        this._track.addEventListener('onChanged', this._trackonchangedbinding);

        // connect with playing already active
        setTimeout(() => {
            if (this._track.playing) this.playClick();
        },500);

        this._globalDragStart = this._globalDragStart.bind(this);
        DraggingStatus.addEventListener('onDragStart', this._globalDragStart);
        this._globalDragEnd = this._globalDragEnd.bind(this);
        DraggingStatus.addEventListener('onDragEnd', this._globalDragEnd);
    }
    componentWillUnmount() {
        if (PeerManager) {
            PeerManager.removeEventListener('remote_data', this.remoteMessageBinding);
        }
        
        this._track.removeEventListener('onChanged', this._trackonchangedbinding);

        DraggingStatus.removeEventListener('onDragStart', this._globalDragStart);
        DraggingStatus.removeEventListener('onDragEnd', this._globalDragEnd);
    }
    componentDidUpdate(prevProps, prevState) {
        var shouldupdate = false;
        var keys = Object.keys(prevState)
        for (var i in keys) {
            var key = keys[i];
            if (prevState[key] !== this.state[key]) {
                shouldupdate = true;
                break;
            }
        }
        if (shouldupdate) {
            this.updateConfig();
        }
    }

    _globalDragStart(e) {
        this.setState({
            draggingStatusActive: true,
            draggingStatusType: e.detail.type,
        })
    }

    _globalDragEnd(e) {
        this.setState({
            draggingStatusActive: false,
            draggingStatusType: e.detail.type,
        })
    }

    _onTrackChanged(e) {
        console.log('TRACK CHANGED', this._track.files.length, this.state.files.length)
        var stateData = {};
        this._persistent.forEach((prop) => {
            if (prop in e.detail.changed) {
                stateData[prop] = e.detail.changed[prop].new;
            }
        });
        
        const playcounts = this._track.files.map((file)=>{return {key:file.key, value:0}; });
        var waverefs = {};
        if (this._track.files.length != this.state.playcount.length) {
            stateData.playcount = playcounts;
            console.log('track files list changed');
            
            this._track.files.forEach(file => {
                if (this._waveRefs[file.key]) waverefs[file.key] = this._waveRefs[file.key];
                else {
                    waverefs[file.key] = React.createRef();
                }
            });
            this._waveRefs = waverefs;
        }
        this.setState(stateData);
    }

    updateConfig() {
        // Informs parent that the persistent config of this track has changed
        if (this.props.onChange) {
            var changedata = {};
            for(var i in this._persistent) {
                var key = this._persistent[i];
                changedata[key] = this.state[key];
            }
            this.props.onChange(this, changedata);
        }
    }

    onSoundFinished(ind, sound, playing) {
        if (this.state.loop) {
            var nextToPlay = Math.floor(this._track.random() * this.state.files.length);
            var newplaycount = this.state.playcount;
            newplaycount[nextToPlay].value++;
            if (ind !== nextToPlay) newplaycount[ind] = 0;
 
            this.setState({playcount:newplaycount});
        }
        // if we are looping
            // Stop playing that sound
            // Find another to start playing
        // otherwise
            // do nothing
    }

    startFadeInTransition() {
        clearInterval(this.transitionInterval);
        this.transitionInterval = setInterval((() => {
            this.transitionCounter += transitionTimestep;
            var scale = Math.max(0,Math.min(1,  (this.transitionCounter/(this.state.transitionDuration))  ));
            if (this.transitionCounter >= this.state.transitionDuration) {
                this.transitionCounter = this.state.transitionDuration;
                this.setState({
                    transitioning: false,
                    transitionFade: scale,
                })
                clearInterval(this.transitionInterval);
            }
            else {
                this.setState({
                    transitionFade: scale,
                })
            }
        }), transitionTimestep);
        
        this.setState({
            transitioning: true,
        })
    }
    startFadeOutTransition() {
        clearInterval(this.transitionInterval);
        this.transitionInterval = setInterval((() => {
            this.transitionCounter -= transitionTimestep;
            var scale = Math.max(0,Math.min(1,  (this.transitionCounter/(this.state.transitionDuration))  ));
            if (this.transitionCounter <= 0) {
                clearInterval(this.transitionInterval);

                
                const playcounts = this.state.files.map(
                    (file)=>{
                        return {key:file.key, value:0};
                    });

                this.setState({
                    playcount: playcounts,
                    playing:false,
                    transitioning: false,
                    transitionFade: 0.0,
                });
                this.setState({
                })
            }
            else {
                this.setState({
                    transitionFade: scale,
                })
            }
        }), transitionTimestep);
        
        this.setState({
            transitioning: true,
        })
    }
    playClick() {
        if (this.state.files.length <= 0) return;

        // Dont double play if looping
        if (this.state.playing && this.state.loop) {
            return;
        }

        var ind = Math.floor(this._track.random() * this.state.files.length);
        var playcounts = this.state.playcount;
        playcounts[ind].value++;
        if (this.state.loop) {
            this.setState({playcount: playcounts, playing:true});
            this.startFadeInTransition();
        }
        else {
            this.setState({playcount: playcounts});
        }
        this._track.playing = this.state.loop;
        this.forceUpdate();
    }
    stopClick() {
        if (this.state.loop) {
            this.startFadeOutTransition();
            this.setState({playing:false});
            this._track.playing = false;
        }
        else {
            const playcounts = this.state.files.map(
                (file)=>{
                    return {key:file.key, value:0};
                });
            this.setState({playcount: playcounts, playing:false});
            this._track.playing = false;
        }
        this.forceUpdate();

        Object.values(this._waveRefs).forEach(ref => { if (ref.current) ref.current.pause()});
    }

    filesClick() {
        this.setState({showFiles: !this.state.showFiles});
    }

    addFileClick() {
        this.setState({
            showNewFile: !this.state.showNewFile,
            newFileLink: "https://",
            newFileData: "",
        });
    }
    submitFileClick() {
        // Add file based on info
        var fileadd = {
            key: uuid(),
            data: this.state.newFileIsLink ? this.state.newFileLink:this.state.newFileData,
            type: this.state.newFileIsLink ? 'link':'raw',
            nonsound: false,
            name: this.state.newFileIsLink ? this.state.newFileLink:this.state.newFileName,
            regions: {},
        };

        fileadd.nonsound = fileadd.type == 'link' && fileadd.data.includes('youtu');

        // Check if its from the bbc
        // https://sound-effects.bbcrewind.co.uk/search?q=07072060
        if (fileadd.type == 'link' && fileadd.data.includes("sound-effects.bbcrewind")) {
            // https://sound-effects-media.bbcrewind.co.uk/mp3/07071150.mp3
            var url = new URL(fileadd.data);
            var fileid = url.searchParams.get("q");
            fileadd.data = `https://sound-effects-media.bbcrewind.co.uk/mp3/${fileid}.mp3`;
            fileadd.name = `BBC Sound ${fileid}`;
        }

        var newplaycounts = this.state.playcount;
        newplaycounts.push({
            key: fileadd.key,
            value: 0,
        });
        
        this.setState({
            showNewFile: false,
            playcount: newplaycounts,
        });

        this._track.addFile(fileadd);
        this.notifyChange('files', this._track.files);
        this._waveRefs[fileadd.key] = React.createRef();
    }
    toggleNewFileTypeClick() {
        this.setState({newFileIsLink: !this.state.newFileIsLink});
    }
    newFileLinkChanged(e) {
        this.setState({newFileLink: e.target.value});
    }
    
    newFileFileChanged(e) {
        var file = e.target.files[0];
        var reader = new FileReader();

        if (e.target.files && file) {
            reader.onload = ((e) => {
                this.setState({newFileData: e.target.result, newFileName:file.name});
            })
            reader.readAsDataURL(file);
        }
    }

    removeFileClicked(ind) {
        var newplaycounts = this.state.playcount;
        newplaycounts.splice(ind, 1);

        var file = this._track.files[ind]
        this._track.removeFile(ind);
        this.setState({playcount:newplaycounts});
        delete this._waveRefs[file.key];
        
        this.notifyChange('files', this._track.files);
    }

    playSpecificSound(ind) {
        /*var playcounts = this.state.playcount;
        playcounts[ind].value++;
        // no need to inform TrackObject, only playing preview
        this.setState({playcount: playcounts});*/

        var file = this._track.files[ind];
        this._waveRefs[file.key].current.playPause();
    }

    pitchChange(e, value){
        this._track.pitchRange = value;
        this.notifyChange(e, value);
    }

    _changeProp(prop, val) {
        this._track[prop] = val;
        this.notifyChange(prop, val);
    }

    notifyChange(prop, val) {
        if (prop == 'play') {
            PeerManager.sendPlayerSignal({
                track: this._track.uuid,
                data: { action: 'play' },
              });
        }
        else if (prop == 'stop') {
            PeerManager.sendPlayerSignal({
                track: this._track.uuid,
                data: { action: 'stop' },
              });
        }
        else {
            PeerManager.sendChangedTrack(this._track);
        }
    }

    onWaveSurferMounted(i, wavesurfer) {
        var files = this.state.files;
        var file = files[i];
        wavesurfer.load(file.data);
        this._waveRefs[file.key].current = wavesurfer;
        wavesurfer.on("region-removed", this.onWaveRegionRemoved.bind(this,i));
        wavesurfer.on("region-dblclick", this.onWaveRegionDoubleClicked.bind(this,i));
        wavesurfer.on("region-update-end", this.onWaveRegionUpdateEnd.bind(this,i));
    }
    onWaveRegionRemoved(fileInd, region) {
        console.log("REGION rem", fileInd, region);
        var files = this.state.files;
        var file = files[fileInd];
        delete file.regions[region.id];
        files[fileInd] = file;
        this.setState({files});
        this.notifyChange('regions', this._track.files);
        this._track.regions = file.regions;
    }
    onWaveRegionDoubleClicked(fileInd, region, e) {
        console.log("REGION dblclick", fileInd, region, e);
        if (e.altKey) {
            region.remove();
        }
        else {
            setTimeout(() => {
                region.play()
            }, 10)
        }
    }
    onWaveRegionUpdateEnd(fileInd, region) {
        console.log("REGION upd", fileInd, region);
        var files = this.state.files;
        var file = files[fileInd];
        if (file.regions == undefined) file.regions = {};
        file.regions[region.id] = {
            id: region.id,
            start: region.start,
            end: region.end,
        };
        files[fileInd] = file;
        this.setState({files});
        this.notifyChange('regions', this._track.files);
        this._track.regions = file.regions;
    }
    

    render() {

        var newkey = 'TRACK'+this.state.track_id;
        var groupfade = ('groupFade' in this.props) ? this.props.groupFade:1.0
        var volume = this.getVolume(this.state.fade) * this.state.fadeScalar *(this.state.loop?this.state.transitionFade:1.0) * groupfade;


        var playcount = this.state.playcount;
        var playcountchange = false;
        while (this._track.files.length > playcount.length) {
            playcount.push(0);
            playcountchange=true;
        }
        if (playcountchange) this.setState({playcount:playcount});

        // New fiile creation
        let NewFileDiv = undefined;
        if (this.state.showNewFile) {
            NewFileDiv = (
                <div key={newkey+'newfile'} class="newfile">
                    <div class="newfileinput">
                        {this.state.newFileIsLink &&
                            <TextField label="Sound URL" key={newkey+'newfilelink'} value={this.state.newFileLink} onChange={this.newFileLinkChanged}/>
                        }
                        {!this.state.newFileIsLink &&
                            <input key={newkey+'newfilebrowse'} type="file" onChange={this.newFileFileChanged}/>
                        }
                    </div>
                    <Button variant="outlined" key={newkey+'newfiletype'} onClick={this.toggleNewFileTypeClick}> {this.state.newFileIsLink ? "Link":"File"} </Button>
                    <Button variant="outlined" key={newkey+'newfilesubmit'} onClick={this.submitFileClick}>Submit</Button>
                    <Button variant="outlined" key={newkey+'newfilecancel'} onClick={this.addFileClick}>Cancel</Button>
                </div>
            )
        }
        else {
            // Show a button for adding new files
            NewFileDiv = <Button key={newkey+'newfileshow'} onClick={this.addFileClick}>Add File</Button>
        }

        // File list
        let fileListDiv = undefined;
        if (this.state.showFiles) {
            const waveformplugins = [{ plugin: RegionsPlugin, options: {dragSelection: true,}}];
            fileListDiv = (
                <Paper elevation={5} className="filelist">
                    <div className="tip"> Tip: Alt + Double Click to delete a subregion </div>
                    {this.state.files.map((file, i) => {
                        var regions = file.regions || {};
                        return (<div key={file.key} className="fileentry">
                            {(file.nonsound == undefined || !file.nonsound) &&
                                <WaveSurfer plugins={waveformplugins} onMount={this.onWaveSurferMounted.bind(this,i)}>
                                    <WaveForm height="80" cursorColor="red" id={"waveform" + file.key}>
                                        {Object.keys(regions).map((regId) => {
                                            var reg = regions[regId];
                                            return (<Region
                                                key={reg.id}
                                                {...reg}
                                            />)
                                        })}
                                    </WaveForm>
                                </WaveSurfer> }
                            <div className="filedetails">
                                <TextField disabled value={file.name} variant="standard" className="filename"/>
                                
                                <Tooltip title="Preview">
                                    <Button className="controlButton" variant="outlined" onClick={this.playSpecificSound.bind(this,i)} >
                                        <PlayArrowIcon/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Delete File">
                                    <Button className="controlButton" variant="outlined" color="error" onClick={this.removeFileClicked.bind(this,i)}><DeleteForeverIcon/></Button>
                                </Tooltip>
                            </div>
                        </div>);
                    })}
                    {NewFileDiv}
                </Paper>
            )
        }
        
        // Track Configuration
        let ConfigDiv = undefined;
        if (this.state.showConfig) {
            ConfigDiv = (
            <Paper elevation={4} className="configSection">
                <Box>
                    <FormGroup>
                        <FormControlLabel label="Volume Scalar" control={
                            <TextField 
                                className="volumeScalar"
                                hiddenlabel="true" 
                                type="number"
                                inputProps={{
                                  step: "0.1"
                                }}
                                style={{width:"3em","marginRight":"0.5em"}} 
                                onChange={(e)=>{this.setState({fadeScalar: parseFloat(e.target.value), fadeScalarText:e.target.value}); this.notifyChange('fadeScalar', e.target.value); this._track.fadeScalar=e.target.value}} 
                                value={this.state.fadeScalarText} />}/>
                        <FormControlLabel label="Pitch Randomization" control={
                            <Slider
                                className="pitchslider"
                                getAriaLabel={() => 'Pitch Range'}
                                defaultValue={this.state.pitchRange}
                                onChange={this.pitchChange.bind(this)}
                                valueLabelDisplay="auto"
                                step={0.05}
                                min={0}
                                max={2}
                                />}/>
                        <FormControlLabel label="Show File List" control={
                            <Checkbox onClick={this.filesClick} checked={this.state.showFiles} value={this.state.showFiles} />}/>
                    </FormGroup>
                    <Stack className="configButtons" spacing={1}>
                        <Button variant="outlined" color="error" size="sm" onClick={this.props.onRemove}>Delete Track</Button>
                    </Stack>
                    {fileListDiv}
                </Box>
            </Paper>);
        }

        var tracknodes = this._track.files.map((file, i) => 
                <Sound fkey={newkey+file.key} key={newkey + file.key} src={file.data}
                    onSoundFinished={this.onSoundFinished.bind(this,i)}
                    playcount={this.state.playcount[i].value}
                    fade={this.getVolume(this.state.fade) * this.state.fadeScalar *(this.state.loop?this.state.transitionFade:1.0) * groupfade}
                    dodging={this.state.dodging}
                    pitchMin={this.state.pitchRange[0]}
                    pitchMax={this.state.pitchRange[1]}
                    type={file.type}
                    prng={this._track.random.bind(this._track)}
                    regions={file.regions || {}}
                    ></Sound>
            )

        if (this) {

            if (this.props.showControls == undefined || this.props.showControls) {
                return (
                    
                            
                    <Droppable droppableId={JSON.stringify({type:'track_files', track: this.state.track_id})} type="SOUNDS">
                    {(provided) => (
                    <span ref={provided.innerRef}
                        key={newkey} className="track">
                        {provided.placeholder}
                        {
                            this.props.showControls == undefined || this.props.showControls ?
                            (<>
                                <Stack direction="row" alignItems="center">
                                <span xs={4} className="mainsection namesection">
                                    <Stack direction="row" alignItems="center" >
                                    <Button variant={this.state.showConfig?"contained":"text"} onClick={()=>this.setState({showConfig: !this.state.showConfig})} active={this.state.showConfig + ''}>
                                        <SettingsIcon />
                                    </Button>
                                    <TextField key={newkey+'trackname'} className="namefield"
                                        hiddenLabel
                                        id="filled-hidden-label-small"
                                        value={this.state.name}
                                        variant="filled"
                                        size="small"
                                        onChange={(e)=>this._changeProp('name', e.target.value)}
                                        />
                                    </Stack>
                                </span>
                                    
                                <span xs={5} className="mainsection fadersection">
                                    <Stack direction="row" alignItems="center">
                                        <VolumeUp key={newkey+'volupicon'} />
                                        <Slider key={newkey+'fader'} className="slider fader"
                                            aria-label="Volume Fader"
                                            value={this.state.fade}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={1024}
                                            onChange={(e)=>this._changeProp('fade', e.target.value)}
                                            />    
                                    </Stack>
                                </span>

                                <span xs="auto" className="mainsection buttonsection">
                                    <Tooltip title="Play">
                                        <span className="controlButton">
                                        <Button variant={this.state.playing?"contained":"outlined"} onClick={() => {this.playClick();this.notifyChange('play', 'play')}} disabled={this.state.playing} >
                                            <PlayArrowIcon/>
                                        </Button>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Stop">
                                        <span className="controlButton">
                                        <Button variant="outlined" onClick={() => {this.stopClick();this.notifyChange('stop', 'stop')}}>
                                            <StopIcon/>
                                        </Button>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Loop">
                                        <span className="controlButton">
                                        <Button variant={this.state.loop?"contained":"outlined"} onClick={this._changeProp.bind(this, 'loop', !this.state.loop)} >
                                            <LoopIcon/>
                                        </Button>
                                        </span>
                                    </Tooltip>
                                </span>
                                </Stack>
                            {ConfigDiv}
                            </>) : (<></>)
                        }
                        {tracknodes}
                    </span>
                    )}
                </Droppable>
                );
            }
            else {
                return tracknodes;
            }
        }
    }
}

export default Track;