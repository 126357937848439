
import PeerIDPrefix from './PeerIDPrefix';
import { Peer } from 'peerjs';
import DataManager from './data/DataManager';

class PeerManager extends EventTarget {

    setState(data) {
        console.log('peer change', data);
        var event = new CustomEvent('stateChange', {detail:data});
        this.dispatchEvent(event);
    }

    initPeerClient(serverTarget, mode, autoJoin=false, pass=undefined) {
        this.mode = mode;
        this.peerID = undefined;
        this.setState({serverStarted:true});
        // Create own peer object with connection to shared PeerJS server
        this.peer = new Peer(null, {
            debug: 2
        });
    
        this.peer.on('open', function (id) {
          // Workaround for peer.reconnect deleting previous id
          if (this.peer.id === null) {
              console.log('Received null id from peer open');
              this.peer.id = this.lastPeerId;
          } else {
              this.lastPeerId = this.peer.id;
          }
    
          console.log('Peer opened on ID: ' + this.peer.id);
          if(autoJoin) this.join(serverTarget, pass);
        }.bind(this));
    
        this.peer.on('connection', function (c) {
          // Disallow incoming connections
          c.on('open', function() {
              c.send("Sender does not accept incoming connections");
              setTimeout(function() { c.close(); }, 500);
          });
        }.bind(this));
        
        this.peer.on('disconnected', function () {
          console.log('Connection lost. Please reconnect');
          this.setState({connState:'disconnected', backdropOpen:false, showConnectionPrompt:true, connError: 'Connection lost. Please reconnect'})
    
          // Workaround for peer.reconnect deleting previous id
          this.peer.id = this.lastPeerId;
          this.peer._lastServerId = this.lastPeerId;
          this.peer.reconnect();
        }.bind(this));
        this.peer.on('close', function() {
          this.serverConn = null;
          console.log('Connection destroyed');
          this.setState({connState:'closed', backdropOpen:false, showConnectionPrompt:true, connError: 'Connection closed. Please reconnect'})
        }.bind(this));
        this.peer.on('error', function (err) {
          console.log(err);
          this.setState({connState:'closed', backdropOpen:false, showConnectionPrompt:true, connError: 'Connection error:' + err})
        }.bind(this));
    }

    setOutgoingPaused(pause) {
        this.outgoingPaused = pause;
    }

    initPeerServer(peerID, pass) {
        this.pass = pass;
        this.remoteConn = undefined;
        this.playerConns = [];
        this.peerID = peerID;
        this.outgoingPaused = false;
        this.setState({serverStarted:true});
        // Create own peer object with connection to shared PeerJS server
        this.peer = new Peer(PeerIDPrefix + this.peerID.toLowerCase(), {
            debug: 2
        });

        this.peer.on('open', function (id) {
            // Workaround for peer.reconnect deleting previous id
            if (this.peer.id === null) {
                console.log('Received null id from peer open');
                this.peer.id = this.lastPeerId;
            } else {
                this.lastPeerId = this.peer.id;
            }

            this.setState({peerState:'Awaiting connection...', serverStarted:true});
        }.bind(this));
        this.peer.on('connection', function (c) {
            if (c.metadata && c.metadata.type == 'remote') {
                // Allow only a single remote connection
                if (this.remoteConn && this.remoteConn.open) {
                    c.on('open', function() {
                        c.send({error: "Already connected to another client"});
                        setTimeout(function() { c.close(); }, 500);
                    });
                    return;
                }
                if (c.metadata.pass !== this.pass) {
                    c.on('open', function() {
                        c.send({error: "Password incorrect"});
                        setTimeout(function() { c.close(); }, 500);
                    });
                    return;
                }

                this.remoteConn = c;
                console.log("Connected to remote: " + this.remoteConn.peer);
                this.setState({peerState: "Connected"});
                this.remoteConnectionReady(c);
            }
            else if (c.metadata && c.metadata.type == 'player') {
                this.playerConns.push(c);
                console.log("Connected to player: " + c.peer);
                this.playerConnectionReady(c);
            }
        }.bind(this));
        this.peer.on('disconnected', function () {
            this.setState({peerState:"Connection lost. Please refresh", serverStarted:true});
            console.log('Connection lost. Please reconnect');

            // Workaround for peer.reconnect deleting previous id
            this.peer.id = this.lastPeerId;
            this.peer._lastServerId = this.lastPeerId;
            this.peer.reconnect();
        }.bind(this));
        this.peer.on('close', function() {
            this.serverConn = null;
            this.setState({peerState:"Connection destroyed. Please refresh", serverStarted:true});
            console.log('Connection destroyed');
        }.bind(this));
        this.peer.on('error', (err) => {
            console.log(err);
            alert('' + err);
        });
    }

    /**
     * Create the connection between the two Peers.
     *
     * Sets up callbacks that handle any events related to the
     * connection and data received on it.
     */
    join(target, pass=undefined) {
      
        if (!target) return;
  
        this.setState({backdropOpen: true});
  
        // Close old connection
        if (this.serverConn) {
            this.serverConn.close();
        }
  
        // Create connection to destination peer specified in the input field
        this.serverConn = this.peer.connect(PeerIDPrefix + target.toLowerCase(), {
            reliable: true,
            metadata: { type: this.mode, pass },
        });
  
        this.serverConn.on('open', function () {
            console.log("Connected to: " + this.serverConn.peer);
            this._backdrophidedelay = setTimeout(() => {
                this.setState({connState:'connected', backdropOpen:false, showConnectionPrompt:false, connError: ''});
            }, 800);
        }.bind(this));
        // Handle incoming data (messages only since this is the signal sender)
        this.serverConn.on('data', function (data) {
            if (data.error) {
                this._incomingError = data.error;
            }
          console.log('got data from connection?', data)
            this.handleSignal(data);
        }.bind(this));
        this.serverConn.on('close', function () {
            if (this._backdrophidedelay) {
                clearTimeout(this._backdrophidedelay)
                this._backdrophidedelay = undefined;
            }
            console.log("Connection closed");
            var error = this._incomingError !== undefined ? ('Error: ' + this._incomingError) : 'Connection closed';
            this.setState({connState:'connect_closed', backdropOpen:false, showConnectionPrompt:true, connError: error + '. Please reconnect'})
            this._incomingError = undefined;
        }.bind(this));
    }

    remoteConnectionReady(remoteConn) {

        // Send initial whole state
        // By now, we've probably accumulated all loaded tracks in cachedTracksData
        // Since no one can be connected to us as early as during-initg327
        remoteConn.on('open', (data)=>{
            console.log("Connection opened and ready", data);
            setTimeout(this.sendWholeState.bind(this),200)
        })

        remoteConn.on('data', this.handleSignal.bind(this));
        remoteConn.on('close', function () {
            this.setState({peerState:"Connection reset, Awaiting connection..."});
            this.remoteConn = null;
        }.bind(this));
    }

    playerConnectionReady(conn) {

        // Send initial whole state
        // By now, we've probably accumulated all loaded tracks in cachedTracksData
        // Since no one can be connected to us as early as during-initg327
        conn.on('open', (data)=>{
            console.log("Connection opened and ready", data);
            setTimeout(this.sendWholeState.bind(this),200)
            this.setState({playerConnectionCount: this.playerConns.length})
        })
        conn.on('close', function () {
            this.playerConns.splice(this.playerConns.indexOf(conn), 1);
            this.setState({playerConnectionCount: this.playerConns.length})
        }.bind(this));
    }

    sendSignal(conn, data) {
        // Skip sending if paused
        if (this.outgoingPaused) return;

        if (conn && conn.open) {
            conn.send(data);
            console.log("signal sent", data);
        } else {
            console.log('Connection is closed');
        }
    }

    sendRemoteSignal(data) {
        this.sendSignal(this.serverConn, data);
    }
    sendPlayerSignal(data) {
        if (this.playerConns && this.playerConns.length > 0)
            this.playerConns.forEach((c) => this.sendSignal(c,data));
    }

    sendChangedTrack(track) {
        // Pack up data
        if (this.remoteConn) {
            var newdata = track.save('remote', {});
            var new_json = {
                track: track.uuid,
                data: { action: 'update', value:newdata },
            };
            console.log('Sending updated track to remote', new_json);

            this.sendSignal(this.remoteConn, new_json);
        }
        
        if (this.playerConns && this.playerConns.length > 0) {
            var newdata = track.save('player', {});
            var new_json = {
                track: track.uuid,
                data: { action: 'update', value:newdata },
            };
            console.log('Sending updated track to player', new_json);
            
            this.playerConns.forEach((conn) => {
                this.sendSignal(conn, new_json);
            })
        }
    }

    sendWholeState() {
        // Pack up data
        var session = DataManager.library.activeSession;
        if (this.remoteConn) {
            var remote_tracklist_data = session.tracklists.map((tl_id) => {return DataManager.library.getTracklist(tl_id).save('remote')});
            // Data for remote
            var remote_alltrack_data = remote_tracklist_data.map((tl) => {
                return tl.tracks.map((t_id) => {
                    return DataManager.library.getTrackOrGrouptrack(t_id).save('remote');
                });
            }).flat();
            remote_alltrack_data = [...new Set(remote_alltrack_data)]; // Make only uniques
            var remote_alltrack_data_keyed = {};
            remote_alltrack_data.forEach((td) => {remote_alltrack_data_keyed[td.uuid] = td});

            // Don't bother sending grouptracks separate. Remote will treat them the same
            var remote_json = {
                session: session.save('remote'),
                tracklists: remote_tracklist_data,
                tracks: remote_alltrack_data_keyed,
            }
            console.log('Sending current session', remote_json);

            this.sendSignal(this.remoteConn, remote_json);
        }
        
        if (this.playerConns && this.playerConns.length > 0) {
            var player_tracklist_data = session.tracklists.map((tl_id) => {return DataManager.library.getTracklist(tl_id).save('player')});
            // Data for player
            var player_alltrack_data = player_tracklist_data.map((tl) => {
                return tl.tracks.map((t_id) => {
                    return DataManager.library.getTrackOrGrouptrack(t_id).save('player');
                });
            }).flat();
            player_alltrack_data = [...new Set(player_alltrack_data)]; // Make only uniques
            var player_alltrack_data_keyed = {};
            player_alltrack_data.forEach((td) => {player_alltrack_data_keyed[td.uuid] = td});
        
            // Don't bother sending grouptracks separate. Remote will treat them the same
            var player_json = {
                masterTrackObjects: player_alltrack_data_keyed,
                type: 'library',
            }
            
            console.log('Sending tracks to player', player_json);

            this.playerConns.forEach((conn) => {
                this.sendSignal(conn, player_json);
            })
        }
    }

    handleSignal(data) {
        console.log('Recieved signal', data);

        var event = new CustomEvent("remote_data", {detail:data});
        this.dispatchEvent(event);
        if (this.playerConns && this.playerConns.length > 0) {
           this.playerConns.forEach((player) => {
                this.sendSignal(player, data);
            })
        }
    }

}

const instance = new PeerManager();
export default instance;