
import React, { useEffect } from "react";
import Tab from '@mui/material/Tab';
import { TabContext, TabList } from "@mui/lab"
//import { Prompt } from 'react-router-dom';
import { 
  Box,
  TextField,
  Paper,
  Container,
  Button,
  Typography,
  Modal,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
 } from '@mui/material';
 import MenuIcon from '@mui/icons-material/Menu';
 import TopBar from '../components/TopBar';

import { useTabContext } from "@mui/lab/TabContext"

import MakeRandID from '../IDGenerator';
import modalstyle from '../ModalStyle';
import DataManager from '../data/DataManager';
import PeerManager from '../PeerManager';

import { DragDropContext,
         Draggable,
         Droppable,
       } from 'react-beautiful-dnd';

// a little function to help us with reordering the result
export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 6;
export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    textAlign: "right",

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
  };
};

export const getQuestionListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 8,
  width: 350
});

export const getAnswerListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 4,
  width: 250
});

const Answers = props => {
    const { question, questionNum } = props;
    return (
      <Droppable droppableId={`droppable${question.id}`} type={`${questionNum}`} isCombineEnabled com={""/* This is where answers within a question can be dropped */}> 
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getAnswerListStyle(snapshot.isDraggingOver)}
          >
            {question.answers.map((answer, index) => {
              return (
                <Draggable comm={""/* This is the each answer being dragged around */}
                  key={`${questionNum}${index}`}
                  draggableId={`${questionNum}${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <span {...provided.dragHandleProps}>
                        =
                      </span>
                      {answer}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

// fake data generator
const getQuestions = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `question-${k}`,
    content: `question ${k}`,
    answers: [`answer-1`, `answer-2`, `answer-3`]
  }));

class About extends React.Component {
  constructor(props) {
    super(props);

    console.log(getQuestions(3));

    this.state = {
      questions: getQuestions(3)
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    console.log('drop', result);  
    // dropped outside the list
    if (!result.destination) {
      //console.log("no-change");
      return;
    }

    if (result.type === "QUESTIONS") {
      console.log(result);
      const questions = Reorder(
        this.state.questions,
        result.source.index,
        result.destination.index
      );

      this.setState({
        questions
      });
    } else {
      const answers = Reorder(
        this.state.questions[parseInt(result.type, 10)].answers,
        result.source.index,
        result.destination.index
      );

      const questions = JSON.parse(JSON.stringify(this.state.questions));

      questions[result.type].answers = answers;

      this.setState({
        questions
      });
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
        <>
        <TopBar page="about">
            <IconButton edge="end" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                <MenuIcon />
            </IconButton>
        </TopBar>
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragUpdate={this.onDragUpdate}
      >
        <Droppable droppableId="droppable" type="QUESTIONS" comm={""/* This is where questions can be dropped*/}> 
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getQuestionListStyle(snapshot.isDraggingOver)} comm={""/* This is the list of questions which can be dragged around being rendered */}
            >
              
              {this.state.questions.map((question, index) => ( 
                <Draggable
                  key={question.id}
                  draggableId={question.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {question.content}
                      <span {...provided.dragHandleProps}>
                        =
                      </span>
                      <Answers questionNum={index} question={question} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      </>
    );
  }
}

export default About;
  