
class DataObject extends EventTarget {
    constructor(uuid) {
        super();
        this._uuid = uuid;
    }
    _signalChange(data) {
        // don't signal if no changes listed
        if (!data.changed || Object.keys(data.changed).length <= 0) return;

        var e = new CustomEvent('onChanged', {detail: data} );
        this.dispatchEvent(e);
    }

    // internal helper function changing a property and preparing the signal data
    _pushUpdate(output, input, key) {
        output = output || {changed:{}};
        var newVal = (typeof input === "object" &&  key in input) ? input[key] : input
        if (input == undefined) return output; // Lacking input, don't overrwrite current value
        output.changed[key] = { old: this['_' + key], new: newVal};
        this['_' + key] = newVal;
        return output;
    }

    load(json, notify=true) {
        var rtn = {
            changed: {},
        }
        if ('uuid' in json && json.uuid !== this._uuid) {
            rtn = this._pushUpdate(rtn, json, 'uuid');
        }
        if (notify) {
            this._signalChange(rtn);
        }
        return rtn;
    }

    save(context) {
        return {
            uuid: this._uuid,
        };
    }

    get uuid() { return this._uuid; }

    // internal helper function for updating primitive properties and signaling
    _genericSetter(prop, val) {
        if (this['_' + prop] !== val) {
            const upd = this._pushUpdate(null, val, prop);
            console.log('prop change', prop, val);
            this._signalChange(upd);
        }
    }
}

export default DataObject;