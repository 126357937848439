
import React, { useEffect } from "react";
import Tab from '@mui/material/Tab';
import { TabContext, TabList, timelineSeparatorClasses } from "@mui/lab"
//import { Prompt } from 'react-router-dom';
import { 
  Box,
  TextField,
  Paper,
  Container,
  Button,
  Typography,
  Modal,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  SpeedDial,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Pagination,
 } from '@mui/material';
 import MenuIcon from '@mui/icons-material/Menu';
 import TopBar from '../components/TopBar';

 import { DragDropContext,
          Draggable,
          Droppable,
        } from 'react-beautiful-dnd';

import { useTabContext } from "@mui/lab/TabContext"

import MakeRandID from '../IDGenerator';
import modalstyle from '../ModalStyle';
import DataManager from '../data/DataManager';
import PeerManager from '../PeerManager';
import Tracklist from '../components/HostTracklist';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PauseIcon from '@mui/icons-material/Pause';
import { styled, useTheme } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import freesound from "../freesound";
import Sound from './Sound'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

import './DrawerFreesound.scss';
import { faSortAmountAsc } from "@fortawesome/free-solid-svg-icons";

var freesoundloaded = false;
const resultsPerPage = 15;

class FreesoundResult extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            bg: undefined,
            sound: undefined,
            src: undefined,
            playcount: 0,
            playing:false,
        };
        this._file = {
            type: 'link',
            source: 'freesound',
            freesound: {
                id: props.id,
                username: props.username,
            }
        };
    }
    componentDidMount() {
        if (!this.state.sound) {
            freesound.getSound(this.props.result.id, (sound) => {
                this._file.src = sound.previews["preview-hq-mp3"]
                this.setState({
                    bg: sound.images.waveform_m,
                    src: this._file.src,
                    sound: sound,
                })
                this.forceUpdate();
            });
        }
    }

    clicked() {
        if (!this.state.playing) {
            this.playSound();
        }
        else if (this.state.playing) {
            this.stopSound();
        }
    }
    playSound(e) {
        this.setState({playcount:this.state.playcount+1, playing:true});
    }
    stopSound() {
        this.setState({playcount: 0, playing: false});
    }

    onSoundFinished() {
        this.setState({playing:false});
    }

    truncName(name, len=15) {
        if (name.length > len) {
            return name.substring(0, len-3) + '...'
        }
        return name;
    }

    renderInner() {
        return (
                        
            <Box className="freesoundResult"
                sx={{
                    "&::before": {
                        backgroundImage: `url(${this.state.bg})`,
                    },
                }}
                onClick={this.clicked.bind(this)}>
                <h4> 
                     {this.state.playing?<StopIcon/>:<PlayArrowIcon/>} {this.truncName(this.props.result.name, 30)} 
                    <a target="_blank" href={`https://freesound.org/people/${this.props.result.username}/sounds/${this.props.result.id}/`} className="gotoLink">
                        <ExitToAppIcon />
                    </a>
                </h4>
                <Typography className="author"> by {this.truncName(this.props.result.username, 40)} </Typography>
                {this.state.sound ?
                    (<Sound src={this.state.src}
                    onSoundFinished={this.onSoundFinished.bind(this)}
                    playcount={this.state.playcount}
                    fade={1.0}
                    type="link"
                    ></Sound>):(<></>)}
                <Divider />
            </Box>);
    }
    render() {
        if (this.state.sound) 
            return (
                <Draggable
                    key={this.props.result.id}
                    draggableId={JSON.stringify(this.state.sound)}
                    index={this.props.index}
                    >
                    {(provided_drag) => (
                        <Container
                            ref={provided_drag.innerRef}
                            {...provided_drag.draggableProps}
                            {...provided_drag.dragHandleProps}>
                                {this.renderInner()}
                        </Container>
                    )}
                </Draggable>);
        else
            return this.renderInner();
    }
}

class HostDrawer extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            status: undefined,
            searching: false,
            query: "",
            searchPage: 1,
            queryDelay: false,
            searchError: undefined,
            results: [],
            resultCount: 0,
            firstSearch: false,
        };
    }

    componentDidMount() {
        if (!freesoundloaded) {
            freesoundloaded = true;
            freesound.setToken('k874OkX9nBsarBUnj97f76HMeSlryWXJAyenVX3J'); // Please don't steal my token. It's free, go get your own
        }
    }

    showError(e) {
        this.setState({searchError: 'Error searching: ' + e})
    }
    searchClick() {
        this.setState({searchPage:1});
        this.search(1);
    }
    search(page) {
        var searchPage = page || this.state.searchPage;
        if (this.state.query.trim() == "" || this.state.queryDelay || this.state.searching) return;
        this.setState({searching:true, firstSearch:true})
        freesound.textSearch(this.state.query,
            {page: searchPage},
            (sounds) => {
                console.log('Search results: ', sounds);
                this.setState({results:sounds.results, searching:false, resultCount: sounds.count})
            },
            this.showError.bind(this))
    }

    onSearchKeyDown(e) {
        if(e.keyCode == 13){
            this.search();
        }
    }
    handleChangePage(e, page) {
        console.log('page change', page);
        this.setState({searchPage: page, searching: true});
        this.search(page);
    }

    render() {
        return (
            <>
                <Accordion className="freesound">
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>Search Freesound</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className="searchinstructions"> 
                            Click sounds to play/pause <br/>
                            Drag sounds onto tracks to add
                         </Typography>
                        <Container className="search">
                            <TextField label="Search for Sounds" 
                                disabled={this.state.searching}
                                onKeyDown={this.onSearchKeyDown.bind(this)}
                                onChange={(e)=>this.setState({query:e.target.value})} /> 
                            <Button disabled={this.state.searching} variant="outlined" onClick={this.searchClick.bind(this)}> Search </Button>
                        </Container>
                        <Divider />
                        <Container className="resultlist">
                            {(!this.state.searching && this.state.resultCount == 0 && this.state.firstSearch) ? 
                                <Typography className="noresults">No results.</Typography>: <></>}

                            {this.state.searching ? <CircularProgress className="spinner" /> :<>
                            <Droppable droppableId={JSON.stringify({type:"freesound_files"})} type="SOUNDS">
                                {(provided_drop) => (
                                    <div ref={provided_drop.innerRef}
                                        className="results">
                                        {this.state.results.map((r,i) => 
                                            <FreesoundResult result={r} index={i}/>
                                        )}
                                        {provided_drop.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            </>}
                            
                            {this.state.results.length > 0 && (
                                <Pagination
                                    page={this.state.searchPage}
                                    onChange={this.handleChangePage.bind(this)}
                                    size="small"
                                    count={Math.floor(this.state.resultCount / resultsPerPage)}/>
                                )}
                        </Container>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }
}

export default HostDrawer;