
import React, {Component, useEffect } from 'react';

import { useParams, useSearchParams } from "react-router-dom";

import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Button,
    Container,
    Typography,
    Backdrop,
    CircularProgress,
    Stack,
    IconButton,
    Slider,
    TextField,
   } from '@mui/material';
import TopBar from '../components/TopBar';

import {v4 as uuid } from 'uuid';
import { Peer } from 'peerjs';
import PeerIDPrefix from '../PeerIDPrefix';
import Track from '../components/Track';
import "./Player.scss";
import LibraryObject from '../data/LibraryObject';
import SessionObject from '../data/SessionObject';
import DataManager from '../data/DataManager';
import PeerManager from '../PeerManager';

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tracks: [],
      connState: 'begin',
      backdropOpen: false,
      showConnectionPrompt: true, 
      serverTarget: props.server_target,
      masterFade: 1.0,
    }
  }

  componentDidMount() {
    PeerManager.initPeerClient(this.state.serverTarget, "player")

    this._onpeermanagersignal = this.handleData.bind(this);
    PeerManager.addEventListener('remote_data', this._onpeermanagersignal)
    this._onpeerstatechangebinding = this.peerStateChange.bind(this);
    PeerManager.addEventListener('stateChange', this._onpeerstatechangebinding)
  }

  peerStateChange(e) {
    this.setState(e.detail);
  }
  componentWillUnmount() {
    PeerManager.removeEventListener('remote_data', this._onpeermanagersignal)
  }
  handleData(e) {
    var data = e.detail;
    if (data.type==="library") {
        DataManager.makeLocalLibrary(data);
        this._lastData = data;
        this.setState({tracks: Object.keys(data.masterTrackObjects)});
    }
  }


  renderServerConnectPrompt() {
    return (
      <Grid
        container
        spacing={0}
        direction="collumn"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight:'100vh'}}>

        <Stack direction="column" spacing={2} alignItems="center">
          <Typography component='h2'> Please enter a host code to connect </Typography>
          <Stack spacing={2} direction="row">
            <TextField
              defaultValue={this.state.serverTarget}
              label="Host Code"
              onChange={(e) => {this.setState({serverTarget:e.target.value})}}
              sx={{minWidth:"25em"}}
              ></TextField>
            <Button onClick={PeerManager.join.bind(PeerManager, this.state.serverTarget, {})}>
              Connect
            </Button>
          </Stack>
          <Typography color="error.main"> {this.state.connError} </Typography>
        </Stack>
      </Grid>
    )
  }

  renderPlayerPanel() {
    return (<>
        
        <Box sx={{mx:20, py:5}}>
            <Typography> Master Volume </Typography>
            <Slider 
                className="slider fader"
                color={this.state.masterFade>1 ? "error":"primary"}
                aria-label="Master Volume"
                value={this.state.fade}
                valueLabelDisplay="auto"
                min={0}
                max={200}
                defaultValue={100}
                onChange={(e)=>this.setState({masterFade: (e.target.value/100.0)})}
                />
        </Box>
        {this.state.tracks.map((track) => (
            <Track key={track} track={track} showControls={this.props.debug===true} groupFade={this.state.masterFade} ></Track>
        ))}
    </>);
  }

  render() {
    return <>
    
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.backdropOpen}
        >
          <Stack alignItems="center">
            <Typography> Connecting to host... </Typography>
            <CircularProgress color="inherit" />
          </Stack>
      </Backdrop>

      <TopBar page="player"></TopBar>
      
    { this.state.showConnectionPrompt ? this.renderServerConnectPrompt() : this.renderPlayerPanel() }
    </>;
  }
};

  
const PlayerRoute = (props) => {
  //const { server_target } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const server_target = searchParams.get("s");
  return <Player debug={props.debug} server_target={server_target}></Player>
}
export default PlayerRoute;
  