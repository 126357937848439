import DataObject from './DataObject';
import {v4 as uuid} from "uuid";

import SessionObject from './SessionObject';
import TracklistObject from './TracklistObject';
import GrouptrackObject from './GrouptrackObject';
import TrackObject from './TrackObject';


// Helper to find entries removed between old and new
function _findRemoved(oldList, newList) {
    var lost = [];
    oldList.forEach((old) => {
        if(!(old in newList)) {
            lost.push(old);
        }
    })
    return lost;
}

class LibraryObject extends DataObject {
    constructor(inuuid) {
        super(inuuid);
        this._name = "New Session";
        // Currently active session
        this._activeSession = uuid();
        // List of session uuids in the library
        this._sessions = [this._activeSession];
        

        // Master list of all sessions and their data
        this._masterSessionObjects = {};
        // Master list of all tracklists and their data
        this._masterTracklistObjects = {};
        // Master list of all grouptracks and their objects
        this._masterGrouptrackObjects = {};
        // Master list of all tracks and their objects
        this._masterTrackObjects = {};

        // Make an initial session for active session
        // If there is any save data, this will likely get overwritten
        this.makeSession(this._activeSession);


        // We defer refcounts when loading so that all new groups load before triggering any refcounts
        // This prevents the deletion of objects when their reference gets moved
        this._shouldDeferRefcount = false;
        this._deferredTracklistRefcount = [];
        this._deferredGrouptrackRefcount = [];
        this._deferredTrackRefcount = [];

    }
    load(json, notify=true, skipRefCount=false) {
        var rtn = super.load(json,false);
        this._setDeferRefcount(true);

        if ('name' in json && this._name !== json.name) {
            
            rtn = this._pushUpdate(rtn, json, 'name');
        }

        if ('activeSession' in json && this._activeSession !== json.activeSession) {
            
            rtn = this._pushUpdate(rtn, json, 'activeSession');
        }

        if ('sessions' in json && (this._sessions.length !== json.sessions.length ||
            JSON.stringify(this._sessions) !== JSON.stringify(json.sessions))) {
            
            rtn = this._pushUpdate(rtn, json, 'sessions');
        }

        const handleMasterChange = (type) => {
            if (`master${type}Objects` in json) {
                // No onchanged for master lists
                var newObjects = [];
                var lostObjects = [];
                var keys = Object.keys(json[`master${type}Objects`]);
                // Find new and removed objects
                keys.forEach((in_uuid) => {
                    if (!(in_uuid in this[`_master${type}Objects`])) {
                        newObjects.push(in_uuid);
                    }
                });
                Object.keys(this[`_master${type}Objects`]).forEach((in_uuid) => {
                    if (!(in_uuid in json[`master${type}Objects`])) {
                        lostObjects.push(in_uuid);
                    }
                })
    
                // New objects need to be created and bound. They will get loaded during the update below
                newObjects.forEach((in_uuid)=> {
                    this[`make${type}`](in_uuid);
                })
                // Removed sessions need to trigger refcounts
                lostObjects.forEach((in_uuid)=> {
                    this[`_remove${type}`](in_uuid);
                })
    
    
                // Finally, update our master list
                keys.forEach((in_uuid) => {
                    const loaddata = json[`master${type}Objects`][in_uuid];
                    const existingObject = this[`_master${type}Objects`][in_uuid];
                    
                    existingObject.load(loaddata);
                })

                // I don't want to bother notifying changes as no one will listen most likely. They'll listen to the objects themselves
            }
        };
        
        handleMasterChange('Track');
        handleMasterChange('Grouptrack');
        handleMasterChange('Tracklist');
        handleMasterChange('Session');

        if (!skipRefCount) {
            Array.prototype.push.apply(this._deferredTracklistRefcount, Object.keys(this._masterTracklistObjects));
            Array.prototype.push.apply(this._deferredGrouptrackRefcount, Object.keys(this._masterGrouptrackObjects));
            Array.prototype.push.apply(this._deferredTrackRefcount, Object.keys(this._masterTrackObjects));
        }
        else {
            this._deferredTracklistRefcount = [];
            this._deferredGrouptrackRefcount = [];
            this._deferredTrackRefcount = [];
        }
        this._setDeferRefcount(false);

        if (notify) {
            this._signalChange(rtn);
        }
    }
    save(context) {
        var json = super.save();
        json.activeSession = this._activeSession;
        json.sessions = this._sessions;
        json.type="library";

        const handleMasterSave = (type) => {
            var savedata = {};
            Object.keys(this[`_master${type}Objects`]).forEach((obj_uuid) => {
                var obj = this[`_master${type}Objects`][obj_uuid];
                savedata[obj_uuid] = obj.save(context);
            });
            json[`master${type}Objects`] = savedata;
        };
        handleMasterSave('Session');
        handleMasterSave('Tracklist');
        handleMasterSave('Grouptrack');
        handleMasterSave('Track');
        
        return json;
    }

    _onSessionChanged(session, e) {
        // refcount its old tracklists
        // If no session contains the ones removed, remove them
        if ('tracklists' in e.detail.changed) {
            // Tracklists have changed
            const oldList = e.detail.changed.tracklists.old;
            const newList = e.detail.changed.tracklists.new;
            const lost = _findRemoved(oldList, newList);
            this._refcountTracklists(lost);
        }
        this._signalChange({ changed: { session: {old: undefined, new: undefined, child:session.uuid, changed: e.detail.changed}}});
    }
    _onTracklistChanged(tracklist, e) {
        // refcount its old tracks and grouptracks
        // If no tracklist or grouptrack contains the ones removed, remove them
        if ('tracks' in e.detail.changed) {
            // Tracks have changed
            const oldList = e.detail.changed.tracks.old;
            const newList = e.detail.changed.tracks.new;
            const lost = _findRemoved(oldList, newList);
            const lostTracks = [];
            const lostGrouptracks = [];
            lost.forEach((in_uuid) => {
                const type = this.getTrackType(in_uuid);
                if (type === 'track') {
                    lostTracks.push(in_uuid);
                }
                else if (type === 'grouptrack') {
                    lostGrouptracks.push(in_uuid);
                }
            })
            this._refcountGrouptracks(lostGrouptracks);
            this._refcountTracks(lostTracks);
        }
        this._signalChange({ changed: { tracklist: {old: undefined, new: undefined, child:tracklist.uuid, changed: e.detail.changed}}});
    }
    _onGrouptrackChanged(grouptrack, e) {
        // refcount its old tracks
        // If no tracklist or grouptrack contains the ones removed, remove them
        if ('tracks' in e.detail.changed) {
            // Tracklists have changed
            const oldList = e.detail.changed.tracks.old;
            const newList = e.detail.changed.tracks.new;
            const lost = _findRemoved(oldList, newList);
            // Can only contain tracks, no grouptracks
            this._refcountTracks(lost);
        }
        

        this._signalChange({ changed: { grouptrack: {old: undefined, new: undefined, child:grouptrack.uuid, changed: e.detail.changed}}});
    }
    _onTrackChanged(track, e) {
        
        // Propogate changes for save trigger
        this._signalChange({ changed: { track: {old: undefined, new: undefined, child:track.uuid, changed: e.detail.changed}}});
    }

    _removeSession(session_uuid) {
        // Remove session from masterSessionObjects

        // Refcount its tracklists
        if (!(session_uuid in this._masterSessionObjects)) return;
        const toRemove = this._masterSessionObjects[session_uuid];
        this._refcountTracklists(toRemove.tracklists);

        delete this._masterSessionObjects[session_uuid];
    }

    _removeTracklist(tracklist_uuid) {
        // Remove tracklist from masterTracklistObjects
        // Assume that this was done by the library and that no session has a reference to it
        
        // refcount its old tracks and grouptracks
        if (!(tracklist_uuid in this._masterTracklistObjects)) return;
        const toRemove = this._masterTracklistObjects[tracklist_uuid].tracks;
        const lostTracks = [];
        const lostGrouptracks = [];
        toRemove.forEach((in_uuid) => {
            const type = this.getTrackType(in_uuid);
            if (type === 'track') {
                lostTracks.push(in_uuid);
            }
            else if (type === 'grouptrack') {
                lostGrouptracks.push(in_uuid);
            }
        })
        this._refcountGrouptracks(lostGrouptracks);
        this._refcountTracks(lostTracks);
        
        delete this._masterTracklistObjects[tracklist_uuid];
    }
    
    _removeGrouptrack(grouptrack_uuid) {
        // Remove grouptrack from masterGrouptrackObjects
        // Assume that this was done by the library and that no tracklist  has a reference to it
        
        // refcount its old tracklists
        if (!(grouptrack_uuid in this._masterGrouptrackObjects)) return;
        const toRemove = this._masterGrouptrackObjects[grouptrack_uuid];
        this._refcountTrack(toRemove);

        delete this._masterGrouptrackObjects[grouptrack_uuid];
    }
    
    _removeTrack(track_uuid) {
        if (track_uuid in this._masterTrackObjects) {
            delete this._masterTrackObjects[track_uuid];
        }
        // Remove track from masterTrackObjects
        // Assume that this was done by the library and that no tracklist or grouptrack has a reference to it
    }

    _setDeferRefcount(val) {
        if (val) {
            this._deferredTracklistRefcount = [];
            this._deferredGrouptrackRefcount = [];
            this._deferredTrackRefcount = [];
        }
        this._shouldDeferRefcount = val;
        if (!val) {
            // Trigger deferred refcounts
            // Start with tracklists, then grouptracks, then tracks.
            // I dont think the order matters because everything should be in its final state
            // But removing tracklists may trigger the removal of more grouptracks and such
            
            this._refcountTracklists(this._deferredTracklistRefcount);
            this._refcountGrouptracks(this._deferredGrouptrackRefcount)
            this._refcountTracks(this._deferredTrackRefcount);
        }
    }

    // Called when a session stops referencing a tracklist object
    // Go through other sessions to see if no references exist and if so, remove it
    _refcountTracklists(tracklist_uuids) {
        // If no session contains them, remove them
        
        if (this._shouldDeferRefcount) {
            // We're deferring, so add these to the deferred list
            Array.prototype.push.apply(this._deferredTracklistRefcount, tracklist_uuids);
            return;
        }

        const remove = tracklist_uuids.filter((tracklist_uuid) => {
            
            let referenced = false;
            const sessions = Object.values(this._masterSessionObjects);
            for (var i in sessions) {
                var session = sessions[i];
                if (session.tracklists.indexOf(tracklist_uuid) >= 0) {
                    referenced = true;
                    break;
                }
            }
            return !referenced;
        });

        remove.forEach((o) => {
            this._removeTracklist(o);
        });
    }
    // Called when a tracklist stops referencing a grouptrack object
    // Go through other tracklists to see if no references exist and if so, remove it
    _refcountGrouptracks(grouptrack_uuids) {
        // If no tracklist contains them, remove them

        if (this._shouldDeferRefcount) {
            // We're deferring, so add these to the deferred list
            Array.prototype.push.apply(this._deferredGrouptrackRefcount, grouptrack_uuids);
            return;
        }

        const remove = grouptrack_uuids.filter((grouptrack_uuid) => {
            
            let referenced = false;
            const tracklists = Object.values(this._masterTracklistObjects);
            for (var i in tracklists) {
                var tracklist = tracklists[i];
                if (tracklist.tracks.indexOf(grouptrack_uuid) != -1) {
                    referenced = true;
                    break;
                }
            }
            return !referenced;
        });

        remove.forEach((o) => {
            this._removeGrouptrack(o);
        });
    }
    // Called when a tracklist or grouptrack stops referencing a track object
    // Go through other tracklists and grouptracks to see if no references exist and if so, remove it
    _refcountTracks(track_uuids) {
        // If no tracklist or grouptrack contains them, remove them
        // Make sure to check if another refcount has already removed that track first
        if (track_uuids.length <= 0) return;

        if (this._shouldDeferRefcount) {
            // We're deferring, so add these to the deferred list
            Array.prototype.push.apply(this._deferredTrackRefcount, track_uuids);
            return;
        }

        const remove = track_uuids.filter((track_uuid) => {
            
            let referenced = false;
            const tracklists = Object.values(this._masterTracklistObjects);
            for (var i in tracklists) {
                var tracklist = tracklists[i];
                if (tracklist.tracks.indexOf(track_uuid) != -1) {
                    referenced = true;
                    break;
                }
            }
            if (!referenced) {
                const grouptracks = Object.values(this._masterTracklistObjects);
                for (var gti in grouptracks) {
                    var grouptrack = grouptracks[gti];
                    if (grouptrack.tracks.indexOf(track_uuid) != -1) {
                        referenced = true;
                        break;
                    }
                }
            }
            return !referenced;
        });

        remove.forEach((o) => {
            this._removeTrack(o);
        });
    }

    get activeSessionId() { return this._activeSession; }
    set activeSessionId(val) { 
        this._genericSetter('activeSession', val);
    }
    get activeSession() {
        return this._masterSessionObjects[this._activeSession];
    }

    get sessions() { return this._sessions.map((e)=>e); }
    
    // Getters for objects
    getSession(session_uuid) {
        return this._masterSessionObjects[session_uuid];
    }
    getTracklist(tracklist_uuid) {
        return this._masterTracklistObjects[tracklist_uuid];
    }
    getGrouptrack(grouptrack_uuid) {
        return this._masterGrouptrackObjects[grouptrack_uuid];
    }
    getTrack(track_uuid) {
        return this._masterTrackObjects[track_uuid];
    }

    // Returns 'track' or 'grouptrack'
    getTrackType(track_uuid) {
        if (track_uuid in this._masterTrackObjects) {
            return 'track';
        }
        if (track_uuid in this._masterGrouptrackObjects) {
            return 'grouptrack';
        }
        return undefined;
    }
    getTrackOrGrouptrack(in_uuid) {
        const type = this.getTrackType(in_uuid);
        if (type === 'track') {
            return this.getTrack(in_uuid);
        }
        else if (type === 'grouptrack') {
            return this.getGrouptrack(in_uuid);
        }
        return undefined;
    }

    // Remove session from library
    removeSession(session_uuid) {
        var old = this._sessions.map((e)=>e);
        var session = this._sessions[session_uuid];

        this._sessions.splice(this._sessions.indexOf(session_uuid),1);
        this._signalChange({ changed: { sessions: {old: old, new: this._sessions}}});

        // Removed session, so refcount all its tracklists
        this._refcountTracklists(session.tracklists);
    }

    // Factories for objects
    makeSession(in_uuid=null) {
        const session = new SessionObject(in_uuid || ('session-' + uuid()));
        this._masterSessionObjects[session.uuid] = session;
        session.addEventListener('onChanged', this._onSessionChanged.bind(this, session));

        this._signalChange({changed: {masterSessionObjects:{new:this._masterSessionObjects, old:{}}}});
        return session;
    }
    makeTracklist(in_uuid=null) {
        const tracklist = new TracklistObject(in_uuid || ('tracklist-' + uuid()));
        this._masterTracklistObjects[tracklist.uuid] = tracklist;
        tracklist.addEventListener('onChanged', this._onTracklistChanged.bind(this, tracklist));

        this._signalChange({changed: {masterTracklistObjects:{new:this._masterTracklistObjects, old:{}}}});
        return tracklist;
    }
    makeGrouptrack(in_uuid=null) {
        const grouptrack = new GrouptrackObject(in_uuid || ('grouptrack-' + uuid()));
        this._masterGrouptrackObjects[grouptrack.uuid] = grouptrack;
        grouptrack.addEventListener('onChanged', this._onGrouptrackChanged.bind(this, grouptrack));

        this._signalChange({changed: {masterGrouptrackObjects:{new:this._masterGrouptrackObjects, old:{}}}});
        return grouptrack;
    }
    makeTrack(in_uuid=null) {
        const track = new TrackObject(in_uuid || ('track-' + uuid()));
        this._masterTrackObjects[track.uuid] = track;
        track.addEventListener('onChanged', this._onTrackChanged.bind(this, track));

        this._signalChange({changed: {masterTrackObjects:{new:this._masterTrackObjects, old:{}}}});
        return track;
    }

}

export default LibraryObject;