
import {v4 as uuid} from 'uuid';
import LibraryObject from './LibraryObject';
import db from './DexieDB';

const LIBRARY_KEY = 'data_library';

class DataManager {
    constructor() {
        this._cachedRawData = {};

    }
    makeLocalLibrary(data) {
        var library = this._library || new LibraryObject(uuid());
        library.load(data, true,true);
        this._library = library;
        return library;
    }

    loadLibrary() {
        if (this._library) {
            return new Promise((res) => res(this._library));
        }
        if (this._libraryLoadingPromise) return this._libraryLoadingPromise;
        // Dummy promise if we ever replace loadLibrary with somethign that loads externally
        this._libraryLoadingPromise = new Promise((res,rej)=> {
            this._library = this._loadLibrary();
            if (!this._library) {
                // Prepopulate with initial group
                this._library = new LibraryObject(uuid());
                var inittracklist = this._library.makeTracklist();
                this._library.activeSession.addTracklist(inittracklist.uuid);
            }
            console.log('Library loaded', this._library, this._library.activeSession);
            this._libraryLoadingPromise = undefined;
            this._library.addEventListener('onChanged', this._onLibraryChange.bind(this));
            res(this._library);
        })
        return this._libraryLoadingPromise;
    }

    get library() { return this._library; }

    _onLibraryChange(e) {
        console.log('Library changed. Saving...')
        this._saveLibrary();
    }

    _saveLibrary() {
        var json = this._library.save('save');
        this.saveDataByKey(LIBRARY_KEY, json);
    }
    _loadLibrary() {
        var json = this.getDataByKey(LIBRARY_KEY);
        if (json) {
            var library = this._library || new LibraryObject(json.uuid);
            library.load(json);
            return library;
        }
        else {
            return null;
        }
    }

    getDataByKey(key) {
        if (key in this._cachedRawData) return this._cachedRawData[key];
        var incoming = JSON.parse(localStorage.getItem(key));
        if (!incoming || Object.keys(incoming).length === 0) {
            return null;
        }
        this._cachedRawData[key] = incoming;
        return incoming;
    }

    saveDataByKey(key, data) {
        console.log("SAVE KEY", key, data)
        this._cachedRawData[key] = data;
        localStorage.setItem(key, JSON.stringify(data));
    }

    async exportLocalstorageToFile(fileName) {
  
        /* dump local storage to string */

        var ls = {};
        for (var i = 0; i < localStorage.length; i++) {
            var k = localStorage.key(i);
            var v = JSON.parse(localStorage.getItem(k));
            ls[k] = v;
        }

        var ldb = await db.files.toArray();
        var ldbunique = {}
        ldb.forEach((f) => {ldbunique[f.uuid] = f});

        /* save as blob */

        var saveData = {ls:ls, ldb:Object.values(ldbunique)}
        var textToSave = JSON.stringify(saveData, null, 4)
        var textToSaveAsBlob = new Blob([textToSave], {
                type: "text/json"
            });
        var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);

        /* download without button hack */

        var downloadLink = document.createElement("a");
        downloadLink.download = fileName;
        downloadLink.innerHTML = "Download File";
        downloadLink.href = textToSaveAsURL;
        downloadLink.onclick = function (e) {
            document.body.removeChild(e.target);
        };
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    startImportLocalstorage() {
        return new Promise((res, rej) => {
            var input = document.createElement('input');
            input.type="file";
            input.onchange = (e) => {
                res(this.importLocalStorageFromFile(e.target.files));
            }
            input.click();
        });
    }
    importLocalStorageFromFile(files) {
        return new Promise((res,rej) => {
            if (files.length <= 0) rej();

            var reader = new FileReader();
            reader.onload = (async (e) => {
                var indata = JSON.parse(e.target.result);
                console.log('Got import data', indata);
                // Load in localstorage
                const ls = indata.ls
                Object.keys(ls).forEach((key) => {
                    var data = ls[key];
                    this.saveDataByKey(key, data);
                });
                // Load in indexeddb
                await db.files.clear()
                Promise.all(indata.ldb.map((file) => {
                    return db.files.add(file);
                }))
                .then((e)=> {
                    console.log("Finished loading indexeddb");
                    // Reload everything
                    this._loadLibrary();
                    res();
                });
            })
            reader.readAsText(files.item(0));
        });
    }
}

const instance = new DataManager();

export default instance;