import DataObject from './DataObject';

class SessionObject extends DataObject {
    constructor(uuid) {
        super(uuid);
        this._name = 'New Session';
        this._tracklists = [];
    }
    load(json, notify=true) {
        var rtn = super.load(json,false);

        if ('name' in json && this._name !== json.name) {
            
            rtn = this._pushUpdate(rtn, json, 'name');
        }

        if ('tracklists' in json && (this._tracklists.length !== json.tracklists.length ||
            JSON.stringify(this._tracklists) !== JSON.stringify(json.tracklists))) {
            
            rtn = this._pushUpdate(rtn, json, 'tracklists');
        }

        if (notify) {
            this._signalChange(rtn);
        }
    }
    save(context) {
        var json = super.save();
        json.name = this._name;
        json.tracklists = this._tracklists;
        json.type = 'session';
        return json;
    }

    get uuid() { return this._uuid; }
    get name() { return this._name; }
    set name(val) { 
        this._genericSetter('name', val);
    }

    get tracklists() { return this._tracklists; }

    addTracklist(tracklist_uuid) {
        var old = this._tracklists.map((e)=>e);
        this._tracklists.push(tracklist_uuid);
        this._signalChange({ changed: { tracklists: {old: old, new: this._tracklists}}});
    }

    removeTracklist(tracklist_uuid) {
        var old = this._tracklists.map((e)=>e);
        this._tracklists.splice(this._tracklists.indexOf(tracklist_uuid),1);
        this._signalChange({ changed: { tracklists: {old: old, new: this._tracklists}}});
    }
}

export default SessionObject;