import Dexie from 'dexie';

const db = new Dexie('rpgdjdb');
db.version(1).stores({
  files: '++id, data, trackuuid', // Primary key and indexed props
});

export default db;


/*

Adding a file:

const id = await db.files.add({
    name: name,
    data: data
});

Getting a file:

const friends = useLifeQuery(
    () => db.friends.toArray()
);

friends?.map(friend => {name: friend.name, data: friend.data});

// The result will be undefined momentarily before the very initial result arrives - which explains why we refer it as friends? rather than friends.


const data = await db.files.where('id').equals(id).first();
*/