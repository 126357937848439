
import React from 'react';
import DragStatus from '../GlobalDragStatus';
import "./DragNotifier.scss"


const dragClass = 'dragHere';

class DragNotifier extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      dragging:false,
      type:undefined,
    };
  }
  componentDidMount() {
    this.onDragStart=this.onDragStart.bind(this);
    DragStatus.addEventListener('onDragStart',this.onDragStart);
    this.onDragEnd=this.onDragEnd.bind(this);
    DragStatus.addEventListener('onDragEnd',this.onDragEnd);
  }

  componentWillUnmount() {
    DragStatus.removeEventListener('onDragStart',this.onDragStart);
    DragStatus.removeEventListener('onDragEnd',this.onDragEnd);
  }

  onDragStart({detail}) {
    this.setState({dragging:true, type:detail.type});
  }
  onDragEnd({detail}) {
    this.setState({dragging:false, type:detail.type});
  }

  render() {
    return <span className={'dragNotif ' + ((this.state.dragging && this.state.type === this.props.type)?dragClass:'')}></span>
  }
}

export default DragNotifier;