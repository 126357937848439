import React from 'react';
import RemoteEntry from './RemoteEntry'
import { 
    Stack,
    Slider,
    Box,
    Button,
     } from '@mui/material';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

export default class RemoteFader extends RemoteEntry {
    onButton() {
        this.props.onInput({
            action: this.props.data.playing ? 'stop':'play',
        })
    }
    onSlider(e, val) {
        this.props.onInput({
            action: 'fade',
            value: val,
        })
    }

    render() {
        return (
            <Box 
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    width:1,
                    height:1,
                }}
                >
                <Stack 
                    height={1}
                    width={1}
                    direction="column"
                    alignItems="center"
                    spacing={2}
                    justifyContent="space-evenly"
                    >
                    <Slider className="fader"
                        sx={{
                            '& input[type="range"]': {
                            WebkitAppearance: 'slider-vertical',
                            },
                            height:1,
                        }}
                        orientation="vertical"
                        defaultValue={this.props.data.fade}
                        aria-label="Volume"
                        valueLabelDisplay="auto"
                        min={0}
                        max={1024}
                        onChange={this.onSlider.bind(this)}
                        />
                    <Button 
                        variant={this.props.data.playing ? "contained":"outlined"}
                        sx={{
                            width:1,
                            height:1/8,
                        }}
                        onClick={this.onButton.bind(this)}
                        >
                            {
                                this.props.data.playing
                                    ? <PlayArrowIcon/>
                                    : <StopIcon/>
                            }
                            {this.props.data.name}
                    </Button>
                </Stack>
            </Box>
        )
    }
  }