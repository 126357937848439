import TracklistObject from './TracklistObject';

class GrouptrackObject extends TracklistObject {
    // So far no difference from tracklist
    // But making a separate class for posterity
    constructor(uuid) {
        super(uuid);

        this._playing = false;
        this._loop = false;
    }
    load(json, notify=true) {
        var rtn = super.load(json,false);

        const compGeneric = (key) => {
            return (key in json) && (json[key] !== this['_' + key]);
        }

        const genprops = ['playing', 'loop'];
        genprops.forEach((p) => {
            if (compGeneric(p)) {
                rtn = this._pushUpdate(rtn, json, p);
            }
        })

        if (notify) {
            this._signalChange(rtn);
        }
    }
    save(context) {
        var json = super.save();
        json.name = this._name;
        json.type = 'grouptrack';

        if (context !== 'save') {
            json.playing = this._playing;
        }
        const genprops = ['loop',];
        genprops.forEach((p) => {
            json[p] = this['_' + p];
        })
        return json;
    }
    get playing() { return this._playing; }
    set playing(val) { this._genericSetter('playing', val);}
    get loop() { return this._loop; }
    set loop(val) { this._genericSetter('loop', val);}
}

export default GrouptrackObject;