import DataObject from './DataObject';
import seedrandom from 'seedrandom';
import db from './DexieDB';

class TrackObject extends DataObject {
    constructor(uuid) {
        super(uuid);
        
        this._name = 'New Track';
        this._files = [];
        this._playing = false;
        this._loop = false;
        this._fadeScalar = 1.0;
        this._fade = 200;
        this._pitchRange = [1.0, 1.0];
        this._regions = {};
        this._randSeed = seedrandom(Math.random() + 'aksjdansiobfaoisnaosd', {state:true});

        this._filesDirty=false;
    }

    async _getFileCollectionFromJson() {

        console.log(this._name, "fetching files from indexdb where trackuuid matches:", this._uuid);
        return db.files.where('trackuuid').equals(this._uuid).toArray()
        .then((e) => {
            var unique = {};
            e.forEach((f) => {
                unique[f.uuid] = f;
            });
            return Object.values(unique)
        });
        /*return await Promise.all(filesJson.map(async (id) => {
            const entry = await db.files.where('id').equals(id).first();
            if (entry)
                return JSON.parse(entry.data);
            return null;
        }));*/
    }

    async _saveFileCollectionFromJson(filesJson) {
        // Deletes the files db entries for this track and makes new ones for the passed json blob
        // Returns the new json blob of the new IDs:
        // [id1, id2, ...]

        // Delete previous files
        await db.files.where('trackuuid').equals(this._uuid).delete();

        // Add new files
        var rtn = await Promise.all(filesJson.map(async (file) => {
            const id = await db.files.add({
                uuid: file.key,
                trackuuid: this._uuid,
                data: JSON.stringify(file)
            });
            return id;
        }));

        return rtn;

    }

    load(json, notify=true) {
        var rtn = super.load(json,false);

        if ('files' in json &&
            (this._files.length !== json.files.length ||
            JSON.stringify(this._files) !== JSON.stringify(json.files))) {
            
            rtn = this._pushUpdate(rtn, json, 'files');
        }
        else if (json.save_to_indexdb) {
            // Load files from db
            console.log("Attempting to load files from indexdb")
            this._getFileCollectionFromJson().then((e) => {
                this._files = e.map((f)=>JSON.parse(f.data));
                console.log("Got files from indexdb", this._files)
                this._signalChange({ changed: { files: {old: [], new: this._files}}});
            })
        }

        const compGeneric = (key) => {
            return (key in json) && (json[key] !== this['_' + key]);
        }

        const genprops = ['name', 'playing', 'loop', 'fadeScalar', 'fade', 'pitchRange', 'randSeed', 'regions'];
        genprops.forEach((p) => {
            if (compGeneric(p)) {
                rtn = this._pushUpdate(rtn, json, p);
            }
        })

        if ('randSeed' in json) {
            // Sync randomness. Don't bother sending updates since it shouldn't trigger any
            this._randSeed = seedrandom('', {state: json.randSeed});
        }

        if (notify) {
            this._signalChange(rtn);
        }
    }
    save(context) {
        var json = super.save();
        json.name = this._name;
        json.type = 'track';

        if (context == 'player') {
            json.randSeed = this._randSeed.state();
        }

        // Props saved and streamed but not sent to remote

        if (context == 'player') {
            json.files = this._files;
        }
        else if (context == 'save') {
            json.save_to_indexdb = true;
            if(this._filesDirty) {
                console.log("Saving ", this._name, " with " , this._files.length, "files");
                this._filesDirty = false;
                var prom = this._saveFileCollectionFromJson(this._files);
                //var test = this.allSynchronously([prom]);
                prom.then((e)=> {
                    console.log("INDEXDB SAVE RESOLVED", e);
                    // We don't save to the json, we let the getter just find all with our track uuid
                });
            }
        }
        // Props not saved
        if (context !== 'save') {
            json.playing = this._playing;
        }
        // Props saved, streamed, and remote
        const genprops = ['name', 'loop', 'fadeScalar', 'fade', 'pitchRange', 'regions'];
        genprops.forEach((p) => {
            json[p] = this['_' + p];
        })
        return json;
    }

    get uuid() { return this._uuid; }
    get name() { return this._name; }
    set name(val) { this._genericSetter('name', val);}
    get playing() { return this._playing; }
    set playing(val) { this._genericSetter('playing', val);}
    get loop() { return this._loop; }
    set loop(val) { this._genericSetter('loop', val);}
    get fadeScalar() { return this._fadeScalar; }
    set fadeScalar(val) { this._genericSetter('fadeScalar', val);}
    get fade() { return this._fade; }
    set fade(val) { this._genericSetter('fade', val);}
    get pitchRange() { return this._pitchRange; }
    set pitchRange(val) {
        if (this._pitchRange[0] !== val[0] || this._pitchRange[1] !== val[1]) {
            var upd = {changed: { pitchRange:{old: this._pitchRange, new: val}}};
            this._pitchRange = val;
            this._signalChange(upd)
        }
    }
    get regions() { return this._regions; }
    set regions(val) {
        var upd = {changed: { regions:{old: this._regions, new: val}}};
        this._regions = val;
        this._signalChange(upd)
    }

    get files() { return this._files.map((e)=>e); }

    random() {
        var r = this._randSeed();
        return r;
    }

    addFile(filedata) {
        var old = this._files.map((e)=>e);
        this._files.push(filedata);
        this._filesDirty = true;
        this._signalChange({ changed: { files: {old: old, new: this._files}}});
    }

    removeFile(file_index) {
        var old = this._files.map((e)=>e);
        this._files.splice(file_index, 1);
        this._filesDirty = true;
        this._signalChange({ changed: { files: {old: old, new: this._files}}});
    }
}

export default TrackObject;