
import React, { useEffect } from "react";
import Tab from '@mui/material/Tab';
import { TabContext, TabList, timelineSeparatorClasses } from "@mui/lab"
//import { Prompt } from 'react-router-dom';
import { 
  Box,
  TextField,
  Paper,
  Container,
  Button,
  Typography,
  Modal,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  SpeedDial,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Pagination,
 } from '@mui/material';
 import MenuIcon from '@mui/icons-material/Menu';
 import TopBar from '../components/TopBar';

 import { DragDropContext,
          Draggable,
          Droppable,
        } from 'react-beautiful-dnd';

import { useTabContext } from "@mui/lab/TabContext"

import MakeRandID from '../IDGenerator';
import modalstyle from '../ModalStyle';
import DataManager from '../data/DataManager';
import PeerManager from '../PeerManager';
import Tracklist from '../components/HostTracklist';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PauseIcon from '@mui/icons-material/Pause';
import { styled, useTheme } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import freesound from "../freesound";
import Sound from './Sound'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

import './DrawerFreesound.scss';
import { faSortAmountAsc } from "@fortawesome/free-solid-svg-icons";

class DrawerLibrary extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (<>
      <Accordion className="library">
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          >
          <Typography>Your Library</Typography>
          </AccordionSummary>
          <AccordionDetails>

          </AccordionDetails>
      </Accordion>
    </>)
  }
}

export default DrawerLibrary;