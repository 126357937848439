
import React, {Component, useEffect } from 'react';

import { useParams, useSearchParams } from "react-router-dom";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Button,
    Container,
    Typography,
    Backdrop,
    CircularProgress,
    Stack,
    IconButton,
    TextField,
   } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import TopBar from '../components/TopBar';
import { useTabContext } from "@mui/lab/TabContext"
import Tab from '@mui/material/Tab';
import { TabContext, TabList } from "@mui/lab"

import RemoteButton from '../components/RemoteButton'
import RemoteFader from '../components/RemoteFader'

import {v4 as uuid } from 'uuid';
import { Peer } from 'peerjs';
import PeerIDPrefix from '../PeerIDPrefix';
import "./Remote.scss";
import LibraryObject from '../data/LibraryObject';
import SessionObject from '../data/SessionObject';
import PeerManager from '../PeerManager';

function RemoteEntryWrapper(props) {
  if (props.data.loop) {
    return <RemoteFader key={props.data.track_id} onInput={props.onInput} data={props.data}></RemoteFader>
  }
  else {
    return <RemoteButton key={props.data.track_id} onInput={props.onInput} data={props.data}></RemoteButton>
  }
}



const testdef = {
  name: (i)=>{return "Test " + i;},
  fade: ()=>Math.floor(Math.random()*1024),
  loop: ()=>false,
  playing: ()=>false,
  sort: (i)=>i,
}
const predef = [
  {name:'Thanks'},
  {name:'For'},
  {name:'Using'},
  {name:'RPG.DJ'},
  {loop: true, name:'😊', playing:false},
  {name:'Tracks'},
  {name:'Will'},
  {name:'Load'},
  {name:'Shortly'},
]
const ids = [...Array(9)].map(()=>{return uuid()});
var TestData = {}
ids.forEach((u,i) => {
  var predata = predef[i] || {};
  TestData[u] = {
    uuid: u,
    name: predata.name || testdef.name(i),
    fade: predata.fade || testdef.fade(i),
    loop: predata.loop || testdef.loop(i),
    playing: predata.playing || testdef.playing(i),
    sort: predata.sort || testdef.sort(i),
  }
});


class RemotePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cols: props.cols||5,
    }
  }

  onInput(track, data) {
    this.props.onInput({
      track: track.uuid,
      data: data,
    })

    if (data.action == 'play') {
      if (track.loop) track.playing = true;
    }
    else if (data.action=="stop") {
      track.playing = false;
    }
    //this._trackRefs[track.uuid].current.forceUpdate();
    this.forceUpdate();
  }

  getSortedData(incoming, cols=4) {
    var outdata = [];
    var data = incoming.sort((a,b)=>a.sort-b.sort)
    var num = data.length;

    var i = 0;

    var iterarr = [];
    var sizethisrow = cols;
    var sizenextrow = cols;
    while (i < num) {
      if (iterarr.length < sizethisrow) {
        // There's room in this row for another
        
        // We add the next one
        data[i].coord = {y:outdata.length, x:iterarr.length}
        iterarr.push(data[i])
        if (data[i].loop) {
          // this object is a fader, subtract an open slot from the next row
          sizenextrow--;
        }
        i++;
      }
      else {
        /*if (iterarr.length == 0)  {
          iterarr.push({
            track_id: 'pad-' + outdata.length+'-'+iterarr.length,
            coord: {y:outdata.length, x:iterarr.length},
            empty:'none',
          })
        }*/

        // We have filled this row
        outdata.push(iterarr);
        iterarr = [];
        sizethisrow = sizenextrow;
        sizenextrow = cols;
      }
    }
    // There's some leftover not added
    if (iterarr.length > 0) {
      
      // The current row is partial, pad it out
      /*while (iterarr.length < sizethisrow) {
        iterarr.push({
          track_id: 'pad-' + outdata.length+'-'+iterarr.length,
          coord: {y:outdata.length, x:iterarr.length},
          empty:'placeholder',
        })
      }*/
      outdata.push(iterarr)
    }
    // The next row has some pushover, pad it out
    if (sizenextrow < cols) {
      iterarr = [];
      // The current row is partial, pad it out
      while (iterarr.length < sizenextrow) {
        iterarr.push({
          track_id: 'pad-' + outdata.length+'-'+iterarr.length,
          coord: {y:outdata.length, x:iterarr.length},
          empty:'placeholder',
        })
      }
      outdata.push(iterarr)
    }

    return outdata;
  }

  render() {
    var mytrackdata = this.props.tracks.map((t_id) => this.props.trackdata[t_id]);
    var data = this.getSortedData(mytrackdata, this.state.cols);
    
    return (
    <TableContainer className="remote" component={Paper} sx={{height:1}}>
      <Table sx={{}} aria-label="spanning table">
        
        <TableBody>
          {data.map((row, Y) => (
            <TableRow key={Y}>

              {row.map((track, X) => (
                <TableCell key={track.uuid} rowSpan={track.loop ? 2:1} className={track.empty=="none"?"blankcell":""}>
                  <Box className="cellbox"> 
                    {(() => {
                      if (track.empty == "placeholder") {
                        return (<Button className="cellbtn" variant="outlined" disabled> </Button>);
                      }
                      else if (track.empty == "none") {
                        return (<span></span>);
                      }
                      else {
                        return <RemoteEntryWrapper onInput={this.onInput.bind(this, track)} data={track} className="cellbtn"></RemoteEntryWrapper>;
                      }
                      }) ()}
                      
                  </Box> 
                </TableCell>
              ))}

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    );
  }
};



export function TabPanel(props) {
  const {
    children,
    className,
    style,
    index,
    value,
    ...other
  } = props
  const context = useTabContext()

  if (context === null) {
    throw new TypeError('No TabContext provided')
  }
  const tabId = context.value

  return (
    <div
      className={className}
      style={{
        width: '100%',
        ...style,
        position: 'absolute',
        left: 0,
        visibility: value === index ? 'visible' : 'hidden',
      }}
      {...other}
    >
      <Container>{children}</Container>
    </div>
  )
}

// I don't know how hooks work so I made this wrap to get this lib to work
function FullScreenWrap(props) {
  const handle = useFullScreenHandle();

  useEffect(() => {
    if (props.enabled) {
      handle.enter();
    }
    else {
      handle.exit();
    }
  }, [props.enabled])

  return (
    <FullScreen onChange={props.onChange} handle={handle}>
      {props.children}
    </FullScreen>
  )
}

class Remote extends Component {
  constructor(props) {
    super(props);

    var testgroupdata = {
      'abcd': {
        uuid: 'abcd',
        name: 'Test',
        tracks: Object.keys(TestData),
      },
    }
    this.state = {
      tracklists: testgroupdata,
      tracks: TestData,
      tabIndex: 0,
      connState: 'begin',
      backdropOpen: props.server_target != undefined,
      fullscreen:false,
      showConnectionPrompt: true, 
      serverTarget: props.server_target,
      serverPass: props.server_pass,
    }
  }

  componentDidMount() {
    PeerManager.initPeerClient(this.state.serverTarget, "remote", true, this.state.serverPass)

    this._onpeermanagersignal = this.handleData.bind(this);
    PeerManager.addEventListener('remote_data', this._onpeermanagersignal)
    this._onpeerstatechangebinding = this.peerStateChange.bind(this);
    PeerManager.addEventListener('stateChange', this._onpeerstatechangebinding)
  }

  peerStateChange(e) {
    console.log('STATE CHANGE', e.detail);
    this.setState(e.detail);
  }


  componentWillUnmount() {
    PeerManager.removeEventListener('remote_data', this._onpeermanagersignal)
  }

  handleData(e) {
    var data = e.detail;
    this._session = new SessionObject(data.uuid);
    this._session.load(data);
    this._lastData = data;
    this.setState({tracklists:data.tracklists, tracks: data.tracks});
  }

  handleTabChange(e, val) {
      this.setState({tabIndex: val});
  }

  onInput(group, input) {
    PeerManager.sendRemoteSignal({
      group: group.uuid,
      track: input.track,
      data: input.data,
    });
  }

  renderControlPanel() {
    return (
      <TabContext value={this.state.tabIndex}>
        <TabList value={this.state.tabIndex} onChange={this.handleTabChange.bind(this)}>
          {Object.values(this.state.tracklists).map((g,i) => {
            return <Tab key={g.uuid} label={g.name}/>
          })}

        </TabList>

        {/* Track group lists panel */}
        {Object.values(this.state.tracklists).map((g,i) => {
          return (
            <TabPanel key={g.uuid} value={this.state.tabIndex} index={i}>
              <Container>
                <Paper>
                  <Box
                    sx={{
                      width: "100%",
                    }}>
                    <RemotePage tracks={g.tracks} trackdata={this.state.tracks} onInput={this.onInput.bind(this, g)}/> 
                  </Box>
                </Paper>
              </Container>
            </TabPanel>
          );
        })}
      </TabContext>
    )
  }

  renderServerConnectPrompt() {
    return (
      <Grid
        container
        spacing={0}
        direction="collumn"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight:'100vh'}}>

        <Stack direction="column" spacing={2} alignItems="center">
          <Typography component='h2'> Please enter a host code and password to connect </Typography>
          <Stack spacing={2} direction="row">
            <TextField
              defaultValue={this.state.serverTarget}
              label="Host Code"
              onChange={(e) => {this.setState({serverTarget:e.target.value})}}
              ></TextField>
              <TextField
                defaultValue={this.state.serverPass}
                label="Host Password"
                onChange={(e) => {this.setState({serverPass:e.target.value})}}
                ></TextField>
            <Button onClick={PeerManager.join.bind(PeerManager, this.state.serverTarget, this.state.serverPass)}>
              Connect
            </Button>
          </Stack>
          <Typography color="error.main"> {this.state.connError} </Typography>
        </Stack>
      </Grid>
    )
  }

  render() {

    return <>
    
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.backdropOpen}
        >
          <Stack alignItems="center">
            <Typography> Connecting to host... </Typography>
            <CircularProgress color="inherit" />
          </Stack>
      </Backdrop>

      <TopBar page="remote">
          <IconButton edge="end" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={()=>this.setState({fullscreen:!this.state.fullscreen})}>
              <FullscreenIcon  />
          </IconButton>
      </TopBar>

      <FullScreenWrap 
          enabled={this.state.fullscreen}
          onChange={fullscreen => this.setState({ fullscreen })}
        >
      
        { this.state.showConnectionPrompt ? this.renderServerConnectPrompt() : this.renderControlPanel() }

        {(this.state.fullscreen) && 
          <Button component={Paper} className="exitFullscreenBtn" onClick={()=>this.setState({fullscreen:!this.state.fullscreen})}> <FullscreenExitIcon /> </Button>
        }
      </FullScreenWrap>
    </>
  }
}
  
const RemoteRoute = () => {
  //const { server_target, server_pass } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const server_target = searchParams.get("s");
  const server_pass = searchParams.get("p");
  return <Remote server_target={server_target} server_pass={server_pass}></Remote>
}
export default RemoteRoute;
  