
import React, { useEffect } from "react";
import Tab from '@mui/material/Tab';
import {v4 as uuid } from 'uuid';
import { TabContext, TabList } from "@mui/lab"
//import { Prompt } from 'react-router-dom';
import { 
  Box,
  TextField,
  Paper,
  Container,
  Button,
  Typography,
  Modal,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  SpeedDial,
 } from '@mui/material';
 import MenuIcon from '@mui/icons-material/Menu';
 import TopBar from '../components/TopBar';
 import HostDrawer from '../components/HostDrawer';
 import { DragDropContext,
          Draggable,
          Droppable,
        } from 'react-beautiful-dnd';

import { useTabContext } from "@mui/lab/TabContext"

import MakeRandID from '../IDGenerator';
import modalstyle from '../ModalStyle';
import DataManager from '../data/DataManager';
import PeerManager from '../PeerManager';
import Tracklist from '../components/HostTracklist';
import DragStatus from '../GlobalDragStatus';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PauseIcon from '@mui/icons-material/Pause';

import "./Host.scss"
import DragNotifier from "../components/DragNotifier";

// We make a tabpanel with special visibility so that hidden tabs still render and play sounds
export function TabPanel(props) {
  const {
    children,
    className,
    style,
    value,
    ...other
  } = props
  const context = useTabContext()

  if (context === null) {
    throw new TypeError('No TabContext provided')
  }
  const tabId = context.value

  return (
    <div
      className={className}
      style={{
        width: '100%',
        ...style,
        position: tabId === value ? 'absolute':'fixed',
        left: 0,
        visibility: tabId === value ? 'visible' : 'hidden',
        top: (tabId===value)?undefined:1000000,
      }}
      {...other}
    >
      <Container>{children}</Container>
    </div>
  )
}

class Host extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: "1",
      session: {},
      groups: {},

      showDeleteGroup: false,
      deleteGroupTarget: undefined,
      peerState:'Not Started',
      serverStarted: false,
      peerID: MakeRandID(4),
      pass: MakeRandID(2),
      outgoingPaused: false,
      showDrawer: false,
      dragState: {dragging: false, type:undefined },
    }
    

    if (!DataManager.library) {
      DataManager.loadLibrary()
      .then((library) => {
        this.setState({
          session: library.activeSession,
          groups: library.activeSession.tracklists,
        });
        if (library.activeSession.tracklists.length == 0) {
          this.setState({tabIndex:"0"})
        }
      });
    }
  }
  componentDidMount() {
    this._onsessiondatachangedbinding = this._onSessionDataChange.bind(this);
    DataManager.library.activeSession.addEventListener('onChanged', this._onsessiondatachangedbinding);

    this.setState( {
      session: DataManager.library.activeSession,
      groups: DataManager.library.activeSession.tracklists,
    })

    this._onpeermanagerchangedbinding = this._onPeerManagerChanged.bind(this);
    PeerManager.addEventListener('stateChange', this._onpeermanagerchangedbinding)

    this.updateStorageInfo();
  }
  componentWillUnmount() {
    DataManager.library.activeSession.removeEventListener('onChanged', this._onsessiondatachangedbinding);
    PeerManager.removeEventListener('stateChange', this._onpeermanagerchangedbinding)
  }

  _onPeerManagerChanged(e) {
    this.setState(e.detail);
    this.forceUpdate();
  }

  _onSessionDataChange(e) {
    if ('tracklists' in e.detail.changed) {
      this.setState({groups:e.detail.changed.tracklists.new});
    }
    PeerManager.sendWholeState();
    this.updateStorageInfo();
  }

  handleTabChange(e, val) {

    if (val < Object.keys(this.state.groups).length + 1) {
      this.setState({tabIndex: val})
    }
    else {
      // Clicked last tab, create new
      var { uuid } = DataManager.library.makeTracklist();
      DataManager.library.activeSession.addTracklist(uuid);
      this.setState({tabIndex:val})
    }
  }

  // Called when one of the tracklists changes
  onTracklistChange(tracklist_id) {
    PeerManager.sendWholeState();
  }

  updateGroupName(tracklist_uuid, val) {
    var tracklist = DataManager.library.getTracklist(tracklist_uuid);
    tracklist.name = val;
    this.forceUpdate();
  }

  deleteGroupClicked() {
    this.state.session.removeTracklist(this.state.deleteGroupTarget);
    this.setState({showDeleteGroup:false, tabIndex:(parseInt(this.state.tabIndex)-1)+''})
  }

  liveButtonClicked() {
    var newPause = !this.state.outgoingPaused;
    PeerManager.setOutgoingPaused(newPause);
    this.setState({outgoingPaused: newPause});
    if (!newPause) {
      // no longer paused, update clients to changes
      PeerManager.sendWholeState();
    }
  }


  onDragEnd(result) {


    DragStatus.setStatus(false, result.source.type);
    // Dropped outside the list
    if (result.destination) {
      var destinationData = JSON.parse(result.destination.droppableId);
      var sourceData = JSON.parse(result.source.droppableId);
      if (result.type === "TRACK") {
        if (result.destination.dropableId == result.source.droppableId &&
             result.destination.index == result.source.index) return; // Same spot

        var destinationTracklist = DataManager.library.getTracklist(destinationData.tracklist);
        var sourceTracklist = DataManager.library.getTracklist(sourceData.tracklist);

        var oldDestinationTracks = Array.from(destinationTracklist._tracks);
        var oldSourceTracks = Array.from(sourceTracklist._tracks);

        var [track] = sourceTracklist._tracks.splice(result.source.index, 1);
        destinationTracklist._tracks.splice(result.destination.index, 0, track);

        // Do a check for sanity
        var oldSum = oldDestinationTracks.length + oldSourceTracks.length;
        var newSum = destinationTracklist._tracks.length + sourceTracklist._tracks.length;
        console.log('sums', oldSum, newSum);
        if (oldSum != newSum) {
          alert('Something went wrong. Reverting change.');

          sourceTracklist._tracks = oldSourceTracks
          destinationTracklist._tracks = oldDestinationTracks;
        }
        else {
          destinationTracklist._signalChange({ changed: { tracks: {old: oldDestinationTracks, new: destinationTracklist._tracks}}});
          if (result.source.droppableId !== result.destination.droppableId) {
            sourceTracklist._signalChange({changed: { tracks: {old: oldSourceTracks, new:sourceTracklist } }})
          }
        }
        
        PeerManager.sendWholeState();
      }
      else if(result.type == "SOUNDS") {
        
        if (destinationData.type == "freesound_files") return;
        var destinationTrack = DataManager.library.getTrack(destinationData.track);
        var file = JSON.parse(result.draggableId);
        console.log('DRP FILE', file);
        
        var fileadd = {
            key: 'file'+uuid(),
            data: file.previews['preview-hq-mp3'],
            type: 'link',
            name: `${file.name} by ${file.username} (freesound.org)`,
        };
        

        destinationTrack.addFile(fileadd);
        PeerManager.sendWholeState();
      }
    }
    else if (result.combine) {

    }
  }

  onDragStart(result) {
    DragStatus.setStatus(true, result.type);
  }

  updateStorageInfo() {
    const sm = navigator.storage;
    sm.persisted().then((e)=>this.setState({storage_persisted:e}));
    sm.estimate().then((e)=> {
      this.setState({
        storage_estimate_quota:e.quota,
        storage_estimate_usage:e.usage,
      })
    })
  }

  render() {
    var remoteurl = window.location.protocol + "//" + window.location.host + "/remote?s=" + this.state.peerID + "&p=" + this.state.pass;
    var playerurl = window.location.protocol + "//" + window.location.host + "/player?s=" + this.state.peerID;
    return (
    <>
      <TopBar page="host">
          <IconButton edge="end" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={()=>this.setState({showDrawer:!this.state.showDrawer})}>
              <MenuIcon />
          </IconButton>
      </TopBar>

      {this.state.serverStarted ? (
      <Button className="liveBtn" variant="contained" onClick={this.liveButtonClicked.bind(this)}> 
        {this.state.outgoingPaused ? (
          <>Paused <PauseIcon color="secondary" /> </>
        ):(
          <>Live <FiberManualRecordIcon color="error"/></>
        )}
      </Button>
      ):<></>}

      <Modal
        open={this.state.showDeleteGroup}
        onClose={()=>{this.setState({showDeleteGroup:false})}}
        >
          <Box sx={modalstyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Are you sure you want to delete this group?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              This cannot be undone.
            </Typography>

            <Button variant="outlined" onClick={()=>this.setState({showDeleteGroup:false})} >Cancel</Button>
            <Button variant="outlined" onClick={this.deleteGroupClicked.bind(this)} color="error">Confirm</Button>
          </Box>
      </Modal>
      <DragDropContext
        onDragEnd={this.onDragEnd.bind(this)}
        onDragStart={this.onDragStart.bind(this)}
        >
        <Drawer
          sx={{
            width: 0.3,
            '& .MuiDrawer-paper': {
              width: 0.3,
            },
          }}
          open={this.state.showDrawer}
          anchor="right"
          variant="persistent"
          onClose={()=>this.setState({showDrawer:false})}
          >
          <HostDrawer onClose={()=>this.setState({showDrawer:false})}/>
        </Drawer>
        <TabContext value={this.state.tabIndex}>
          <TabList onChange={this.handleTabChange.bind(this)}>

            <Tab label="Server Settings" value="0"/>

            {Object.values(this.state.groups).map((g,i) => {
              return <Tab key={g+'tabhead'} value={(i+1)+''} style={{position:"relative"}} label={
                <>
                <Droppable droppableId={JSON.stringify({type:'tracklist_tabhead', tracklist:g})} type="TRACK">
                  {(provided_drop) => (
                    <span ref={provided_drop.innerRef} >{DataManager.library.getTracklist(g).name}</span>
                  )}
                </Droppable>
                <DragNotifier type="TRACK"/></>
                }></Tab>
              /*(<>
                <Droppable droppableId={JSON.stringify({type:'tracklist_tabhead', tracklist:g})} type="TRACK">
                  {(provided_drop) => (
                    <Tab key={g+'tabhead'} ref={provided_drop.innerRef} value={(i+1)+''} label={DataManager.library.getTracklist(g).name}/>
                  )}
                </Droppable>
              </>);*/
            })}

            <Tab label="+" value={(Object.keys(this.state.groups).length + 1)+''}/>

          </TabList>


          {/* Settings panel */}
          <TabPanel key="serversettingspanel" value="0">
            <Container>
              <Paper>
                <Box p={3}>
                  <h3> <Typography> Server Settings </Typography> </h3>
                  <TextField sx={{width:0.5}} key='server_id_input' disabled={this.state.serverStarted} label="Host Code" variant="filled" value={this.state.peerID} onChange={(e)=>this.setState({peerID:e.target.value})}/>
                  <br/>
                  <TextField sx={{width:0.5}} key='server_pass_input' disabled={this.state.serverStarted} label="Remote Password" variant="filled" value={this.state.pass} onChange={(e)=>this.setState({pass:e.target.value})}/>
                  <br/>
                  <Button variant="outlined" disabled={this.state.serverStarted} onClick={()=>{PeerManager.initPeerServer(this.state.peerID, this.state.pass);}}> Open Server </Button>
                  <Typography>Remote Connection State: {this.state.peerState}</Typography>
                  <Typography>Connected Players: {this.state.playerConnectionCount || 0}</Typography>
                  {this.state.serverStarted &&
                    (<><Typography> Connect to <a target="_blank" href={remoteurl}>{remoteurl}</a> to remote control</Typography>
                    <Typography> Send the link <a target="_blank" href={playerurl}>{playerurl}</a> to players to stream audio</Typography></>)}
                  <Button variant="outlined" onClick={DataManager.exportLocalstorageToFile.bind(null,'DNDAmbianceConfig.json')}> Save Profile to File </Button>
                  <Button variant="outlined" onClick={()=>{DataManager.startImportLocalstorage().then(()=>{window.location.reload()})}}> Import Profile from File </Button>

                  <Typography>File storage quota: {this.state.storage_estimate_quota/1024}kb</Typography>
                  <Typography>File storage estimate: {this.state.storage_estimate_usage/1024}kb</Typography>
                  <Typography>Percent: {this.state.storage_estimate_usage/this.state.storage_estimate_quota * 100}%</Typography>
                  
                </Box>
              </Paper>
            </Container>
          </TabPanel>

          {/* Track group lists panel */}
          {Object.values(this.state.groups).map((g,i) => {
            return (
              <TabPanel key={g+'tabpanel'} value={(i+1)+''}>
                <Container key={g+'tabsetting_cont'}>
                  <Paper key={g+'tabsetting_cont2'} elevation={2}  className="groupsettings">
                    <Box key={g+'tabsetting_box'} className="groupsettings"
                      sx={{
                        width: "100%",
                        py:  "2em",
                        mx:  "2em",
                        spacing: "1em",
                      }}>
                      <TextField key={g+'tabsetting_name'} label="Tracklist Name" variant="filled" defaultValue={DataManager.library.getTracklist(g).name} onChange={(e)=>this.updateGroupName(g, e.target.value)}/>
                      <Button  key={g+'tabsetting_delete'}variant="outlined" color="error" onClick={()=>{this.setState({showDeleteGroup:true, deleteGroupTarget:g})}}>
                        Delete Tracklist
                      </Button>
                    </Box>
                  </Paper>
                </Container>
                <Container key={g+'tracklist_cont'}>
                  <Paper key={g+'tracklist_paper'} className="tracklist">
                    <Box key={g+'tracklist_box'}
                      sx={{
                        width: "100%",
                        py:  "2em",
                        spacing: "1em",
                      }}>
                      <Tracklist key={g+'tracklist'} track_group={g} onChange={this.onTracklistChange.bind(this)}/> 
                    </Box>
                  </Paper>
                </Container>
              </TabPanel>
            );
          })}
        </TabContext>
      </DragDropContext>
    </>);
  }
};

export default Host;
  