import React, {Component} from 'react';

import Track from "../components/Track"

import { 
  Box,
  TextField,
  Paper,
  Container,
  Button,
  Typography,
  Modal,
  Stack,
 } from '@mui/material';
import DataManager from '../data/DataManager';
import PeerManager from '../PeerManager';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { DragDropContext,
          Draggable,
          Droppable,
        } from 'react-beautiful-dnd';

import DragNotifier from "./DragNotifier";
import modalstyle from '../ModalStyle';

class Tracklist extends Component {
    constructor(props) {
      super(props);
      this._tracklist = DataManager.library.getTracklist(props.track_group);
      this.state = {
        track_group: props.track_group,
        track_ids: this._tracklist.tracks,
        fade: this._tracklist.fade,
  
        showTrackRemoveConfirm:false,
        trackRemoveTargetInd:-1,
      }

  
      this.onTrackRemoveConfirmClick = this.onTrackRemoveConfirmClick.bind(this);
      this.onTrackRemoveCancelClick = this.onTrackRemoveCancelClick.bind(this);
      this.onTrackAdd = this.onTrackAdd.bind(this);
    }
  
    componentDidMount() {
      this.props.onChange(this.state.track_group);
      this._tracklistonchangedbinding = this.onTracklistDataChanged.bind(this);
      this._tracklist.addEventListener('onChanged',this._tracklistonchangedbinding)
    }
    componentWillUnmount() {
      this._tracklist.removeEventListener('onChanged', this._tracklistonchangedbinding);
    }

    onTracklistDataChanged(e) {
      if ('tracks' in e.detail.changed) {
        this.setState({track_ids: this._tracklist.tracks});
      }
      if ('fade' in e.detail.changed) {
        this.setState({fade: this._tracklist.fade});
      }
      if ('name' in e.detail.changed) {
        this.setState({name: this._tracklist.name});
      }
    }

    onTrackRemoveClick(track_id, track) {
      this.setState({trackRemoveTargetInd:track_id, showTrackRemoveConfirm:true});
    }
    onTrackRemoveConfirmClick() {
      DataManager.library.getTrack(this.state.trackRemoveTargetInd).playing=false;
      this._tracklist.removeTrack(this.state.trackRemoveTargetInd);
      this.setState({showTrackRemoveConfirm:false});
    }
    onTrackRemoveCancelClick() {
      this.setState({trackRemoveTargetInd:-1, showTrackRemoveConfirm:false});
    }
  
    onTrackAdd() {
      var newtrack = DataManager.library.makeTrack();
      this._tracklist.addTrack(newtrack.uuid)
    }
  
    render() {
      var key = 'tracklist_' + this.props.track_group;
      return (
        <Container key={key+'cont'}>
          <Modal key={key+'removeconfirm'}
            open={this.state.showTrackRemoveConfirm}
            onClose={()=>{this.setState({showTrackRemoveConfirm:false})}}
            >
              <Box key={key+'removeconfirmbox'} sx={modalstyle}>
                <Typography key={key+'removeconfirmt1'} id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to delete this track?
                </Typography>
                <Typography key={key+'removeconfirmt2'} id="modal-modal-description" sx={{ mt: 2 }}>
                  This cannot be undone.
                </Typography>
                <Button key={key+'removeconfirm_cancelbtn'} variant="outlined" onClick={this.onTrackRemoveCancelClick}>Cancel</Button>
                <Button key={key+'removeconfirm_confirmbtn'} variant="outlined" onClick={this.onTrackRemoveConfirmClick}>Confirm</Button>
              </Box>
          </Modal>


          
          <Stack> 
              <Droppable droppableId={JSON.stringify({type:'tracklist_tracks', tracklist:this._tracklist.uuid})} type="TRACK">
                {(provided_drop) => 
                  <div 
                    ref={provided_drop.innerRef}>


                    {this.state.track_ids.map((t_id, i) => (
                        <Draggable 
                          draggableId={t_id} 
                          index={i} 
                          key={key+'paper'+t_id} 
                        >
                          {(provided_drag) => (
                            <div 
                            ref={provided_drag.innerRef}
                            {...provided_drag.draggableProps}
                          >

                    
                          <Droppable droppableId={JSON.stringify({type:'track_file', track: t_id})} type="SOUNDS">
                              {(provided) => (
                                  
                                  <Paper style={{position:'relative'}}
                                  elevation={2} ref={provided.innerRef}>
                                  {provided.placeholder}
                                    <Box key={key+'box'+t_id} sx={{
                                      padding: "0.5em",
                                      my: "0.2em",
                                    }}>
                                      
                                      <Stack direction="row" alignItems="center">
                                        <span {...provided_drag.dragHandleProps} >
                                          <DragIndicatorIcon/>
                                        </span>
                                        <Track key={key+'track'+t_id} track={t_id} onRemove={this.onTrackRemoveClick.bind(this,t_id)}></Track>
                                      </Stack>
                                    </Box>
                                      <DragNotifier type="SOUNDS"/>
                                  </Paper>
                              )}
                          </Droppable>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided_drop.placeholder}
                  </div>
                }
              </Droppable>
            </Stack>
          <Box className="addtrackbuttoncontainer">
            <Button key={key+'trackaddbtn'} onClick={this.onTrackAdd}>+ Add Track</Button>
          </Box>
        </Container>
      )
    }
  }

  export default Tracklist;