import DataObject from './DataObject';

class TracklistObject extends DataObject {
    constructor(uuid) {
        super(uuid);
        this._name = 'New Tracklist';
        this._tracks = [];
        this._fade = 1024;
    }
    load(json, notify=true) {
        var rtn = super.load(json,false);

        if ('name' in json && this._name !== json.name && json.name) {
            rtn = this._pushUpdate(rtn, json, 'name');
        }

        if ('fade' in json && this._fade !== json.fade && json.fade) {
            rtn = this._pushUpdate(rtn, json, 'fade');
        }

        if ('tracks' in json && json.tracks && (this._tracks.length !== json.tracks.length ||
            JSON.stringify(this._tracks) !== JSON.stringify(json.tracks))) {
            
            rtn = this._pushUpdate(rtn, json, 'tracks');
        }

        if (notify) {
            this._signalChange(rtn);
        }
    }
    save(context) {
        var json = super.save();
        json.name = this._name;
        json.tracks = this._tracks;
        json.fade = this._fade;
        json.type="tracklist";
        return json;
    }

    get uuid() { return this._uuid; }
    get name() { return this._name; }
    set name(val) { 
        this._genericSetter('name', val);
    }
    get fade() { return this._fade; }
    set fade(val) { 
        this._genericSetter('fade', val);
    }

    get tracks() { return this._tracks.map((e)=>e); }

    addTrack(track_uuid) {
        var old = this._tracks.map((e)=>e);
        this._tracks.push(track_uuid);
        this._signalChange({ changed: { tracks: {old: old, new: this._tracks}}});
    }

    removeTrack(track_uuid) {
        var old = this._tracks.map((e)=>e);
        this._tracks.splice(this._tracks.indexOf(track_uuid),1);
        this._signalChange({ changed: { tracks: {old: old, new: this._tracks}}});
    }
}

export default TracklistObject;