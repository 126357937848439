import React from 'react';
import RemoteEntry from './RemoteEntry'
import Button from '@mui/material/Button';

export default class RemoteButton extends RemoteEntry {
    onInput() {
      this.props.onInput({
        action:'play',
      })
    }
    render() {
      return <Button className="cellbtn" variant="outlined" onClick={this.onInput.bind(this)}>{this.props.data.name} </Button>
    }
  }