class DragStatus extends EventTarget {
    constructor() {
        super();
        this.dragging = false;
        this.type = undefined;
    }
    setStatus(dragging, type=undefined) {
        if (dragging !== this.dragging) {
            this.dragging = dragging;
            this.type = type;
            var e = new CustomEvent(dragging ? 'onDragStart':'onDragEnd', {
                detail: {
                    dragging,
                    type,
                }
            })

            this.dispatchEvent(e);
        }
    }
};

const instance = new DragStatus();

export default instance;