
import React, { useEffect } from "react";
import Tab from '@mui/material/Tab';
import { TabContext, TabList } from "@mui/lab"
//import { Prompt } from 'react-router-dom';
import { 
  Box,
  TextField,
  Paper,
  Container,
  Button,
  Typography,
  Modal,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  SpeedDial,
 } from '@mui/material';
 import MenuIcon from '@mui/icons-material/Menu';
 import TopBar from '../components/TopBar';

import { useTabContext } from "@mui/lab/TabContext"

import MakeRandID from '../IDGenerator';
import modalstyle from '../ModalStyle';
import DataManager from '../data/DataManager';
import PeerManager from '../PeerManager';
import Tracklist from '../components/HostTracklist';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PauseIcon from '@mui/icons-material/Pause';
import { styled, useTheme } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';

import DrawerFreesound from './DrawerFreesound';
import DrawerLibrary from "./DrawerLibrary";



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.grey[900],
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));
  

class SessionSection extends React.Component {
    
}

class HostDrawer extends React.Component {

    render() {
        return (
            <>
                <DrawerHeader>
                <IconButton onClick={this.props.onClose}>
                    <ChevronRightIcon />
                </IconButton>
                    <h3> Tools </h3>
                </DrawerHeader>
                <Divider />
                <DrawerFreesound/>
            </>
        )
    }
}

export default HostDrawer;