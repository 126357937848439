import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Host from "./pages/Host";
import Remote from "./pages/Remote";
import Player from "./pages/Player";
import About from "./pages/About";
import NoPage from "./pages/NoPage";
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById("root");

const rootNodes = (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Host />} />
        <Route path="remote/:server_target/:server_pass" element={<Remote />} />
        <Route path="remote" element={<Remote />} />
        <Route path="player/:server_target" element={<Player />} />
        <Route path="player" element={<Player />} />
        <Route path="playerdbg/:server_target" element={<Player debug={true} />} />
        <Route path="playerdbg" element={<Player debug={true} />} />
        <Route path="about" element={<About />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

const root = ReactDOM.createRoot(rootElement);
/*if (rootElement.hasChildNodes()) {
  root.hydrate(rootNodes);
}
else*/
{
  root.render(rootNodes);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)) 
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
