import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

import "./Layout.scss"

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
  })

const Layout = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {/*<nav>
                <ul>
                    <li>
                        <Link to="/">Host</Link>
                    </li>
                    <li>
                        <Link to="/remote">Remote</Link>
                    </li>
                </ul>
            </nav>*/}
            <Container maxWidth="xl">
                <Paper>
                    <Outlet />
                </Paper>
            </Container>
        </ThemeProvider>
    )
};

export default Layout;
